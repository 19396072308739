/* Options:
Date: 2025-02-13 09:01:45
Version: 6.80
Tip: To override a DTO option, remove "//" prefix before updating
BaseUrl: https://localhost:44379

//GlobalNamespace: 
//MakePropertiesOptional: False
AddServiceStackTypes: True
//AddResponseStatus: False
//AddImplicitVersion: 
//AddDescriptionAsComments: True
//IncludeTypes: 
ExcludeTypes: GetInstitutionPersonnelEmailAddresses,InstitutionPersonnelEmailAddressesResponse,GetUserPersonnelEmailAddress,UserPersonnelEmailAddressResponse,SendRegistrationEmail,SendRandomisationEmail
//DefaultImports: 
*/


export interface IReturn<T>
{
    createResponse(): T;
}

export interface IReturnVoid
{
    createResponse(): void;
}

export interface IGet
{
}

export interface IDelete
{
}

export interface IHasSessionId
{
    sessionId: string;
}

export interface IHasBearerToken
{
    bearerToken: string;
}

export interface IPost
{
}

export enum ConsentStatusType
{
    InProgress = 1,
    ReadyForReview = 2,
    Complete = 3,
    DobRequired = 4,
    NewConsentFormRequired = 5,
}

export interface IReportRequest
{
    token: string;
}

export interface IScreeningLogGetSingleById extends IGet
{
    id?: number;
}

export interface IScreeningLogGetCollection extends IGet
{
}

export interface IScreeningLogGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
}

export enum ScreeningStatus
{
    Pending = 1,
    Successful = 2,
    Failed = 3,
}

export enum ScreeningOutcome
{
    ProceedToRegistration = 1,
    ScreenFail = 2,
}

export class ScreeningLog implements IScreeningLog
{
    public id?: number;
    public institutionId?: number;
    public patientId?: number;
    public econsentInvitationId?: number;
    public status?: ScreeningStatus;
    public screeningDate?: string;
    public initials: string;
    public dateOfBirth?: string;
    public outcome?: ScreeningOutcome;
    public proficientInEnglish?: number;
    public patientHasEmail?: number;
    public patientEmail: string;
    public patientUsingEconsent?: number;
    public econsentInvestigator: string;
    public reason?: number;
    public reasonSpecify: string;
    public reasonNotApproached: string;
    public moreThan10NodesInvolved?: boolean;
    public erNegativeOrHer2Positive?: boolean;
    public metastaticDisease?: boolean;
    public previousMalignancy?: boolean;
    public hrtAtTimeOfSurgery?: boolean;
    public presurgicalChemo?: boolean;
    public begunAdjuvantTreatment?: boolean;
    public moreThan8WeerksAfterCompletion?: boolean;
    public plannedFurtherSurgery?: boolean;
    public other?: boolean;
    public otherSpecify: string;
    // @Ignore()
    public patientStudyNumber: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ScreeningLog>) { (Object as any).assign(this, init); }
}

export interface IScreeningLogDelete extends IDelete
{
    screeningLogId?: number;
}

export interface IScreeningLogGetSingleByPatientId extends IGet
{
    patientId?: number;
}

export enum StatusTypeEnum
{
    Waiting = 1,
    Responded = 2,
    NotInterested = 3,
    PatientSigning = 4,
    InvestigatorSigning = 5,
    VerifyingDocument = 6,
    Completed = 7,
    Refused = 8,
}

export class StatusType
{
    public id: StatusTypeEnum;
    public value: string;

    public constructor(init?: Partial<StatusType>) { (Object as any).assign(this, init); }
}

export class Invitation
{
    public id?: number;
    // @Ignore()
    public initials: string;

    public emailAddress: string;
    public institutionCode: string;
    public investigator: string;
    public guid: string;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public dateSent?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public status: StatusType;

    public constructor(init?: Partial<Invitation>) { (Object as any).assign(this, init); }
}

export interface IEoiGetSingleById extends IGet
{
    id?: number;
}

export interface IEoiGetCollection extends IGet
{
}

export interface IEoiGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export class Eoi implements IEoi
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    public invitationId?: number;

    public firstName: string;
    public lastName: string;
    public confirmed?: boolean;
    public dateSubmitted?: string;
    public statusId?: StatusTypeEnum;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public invitation: Invitation;

    public constructor(init?: Partial<Eoi>) { (Object as any).assign(this, init); }
}

export interface IEoiDelete extends IDelete
{
    id?: number;
}

export interface IPatientConsentGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientConsentGetSingleByGuid extends IGet
{
    guid: string;
}

export interface IPatientConsentGetCollection extends IGet
{
}

export interface IPatientConsentGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
}

export enum ConsentTypeEnum
{
    Remote = 1,
    FaceToFace = 2,
    Standard = 3,
}

export class ConsentPicf
{
    public id?: number;
    public consentId?: number;
    public picfTypeId?: number;
    public picfId?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ConsentPicf>) { (Object as any).assign(this, init); }
}

export class ConsentFileUploadTemporary implements IFileUploadTemporary
{
    public guid: string;
    public name: string;
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public image: boolean;

    public constructor(init?: Partial<ConsentFileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class ConsentFileUpload
{
    public extension: string;
    public fileSize?: number;
    public contentType: string;
    public exists: boolean;
    public image: boolean;
    public upload: ConsentFileUploadTemporary;

    public constructor(init?: Partial<ConsentFileUpload>) { (Object as any).assign(this, init); }
}

export class ConsentType
{
    public id: ConsentTypeEnum;
    public value: string;

    public constructor(init?: Partial<ConsentType>) { (Object as any).assign(this, init); }
}

export class PatientConsent
{
    public id?: number;
    // @References("typeof(Optima.ServiceLogic.Models.Dos.EConsent.Eoi)")
    public eoiId?: number;

    public patientId?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.ConsentType)")
    public typeId?: ConsentTypeEnum;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.StatusType)")
    public statusId?: StatusTypeEnum;

    public guid: string;
    public dateStarted?: string;
    public mobileNumber: string;
    public patientSigned?: boolean;
    public datePatientSigned?: string;
    public investigatorSigned?: boolean;
    public dateInvestigatorSigned?: string;
    public documentVerified?: boolean;
    public documentVerifiedBy: string;
    public dateDocumentVerified?: string;
    public dateNotificationSent?: string;
    public storageOfTissueSamples?: boolean;
    // @Ignore()
    public picfs: ConsentPicf[];

    // @Ignore()
    public documentUpload: ConsentFileUpload;

    public type: ConsentType;
    public status: StatusType;
    public eoi: Eoi;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<PatientConsent>) { (Object as any).assign(this, init); }
}

export interface IPatientConsentDelete extends IDelete
{
    id?: number;
}

export interface ILookupGetCollectionByFormDefinitionId extends IGet
{
    formDefinitionId?: number;
}

export interface IEventDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IEventDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IEventDefinitionGetCollection extends IGet
{
}

export interface IFormDefinitionGetSingleById extends IGet
{
    id?: number;
}

export interface IFormDefinitionGetSingleByCode extends IGet
{
    code: string;
}

export interface IFormDefinitionGetCollection extends IGet
{
}

export interface IPatientStateGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientStateGetCollection extends IGet
{
}

export interface ITreatmentGetSingleById extends IGet
{
    id?: number;
}

export interface ITreatmentGetCollection extends IGet
{
}

export interface IMasterGroupGetSingleById extends IGet
{
    id?: number;
}

export interface IMasterGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface IMasterGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetSingleById extends IGet
{
    id?: number;
}

export interface ICollaboratingGroupGetSingleByCode extends IGet
{
    code: string;
}

export interface ICollaboratingGroupGetCollection extends IGet
{
}

export interface ICollaboratingGroupGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICollaboratingGroupGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetSingleById extends IGet
{
    id?: number;
}

export interface ICountryGetSingleByCode extends IGet
{
    code: string;
}

export interface ICountryGetCollection extends IGet
{
}

export interface ICountryGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
}

export interface ICountryGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
}

export interface ICountryGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
}

export interface ICountryGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
}

export interface IInstitutionGetSingleById extends IGet
{
    id?: number;
}

export interface IInstitutionGetSingleByCode extends IGet
{
    code: string;
}

export interface IInstitutionGetCollection extends IGet
{
}

export interface IInstitutionGetCollectionByCountryId extends IGet
{
    countryId?: number;
}

export interface IInstitutionGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
}

export interface IInstitutionGetCollectionByCountryCode extends IGet
{
    countryCode: string;
}

export interface IInstitutionGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
}

export interface IInstitutionGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
}

export interface IPatientGetSingleById extends IGet
{
    id?: number;
}

export interface IPatientGetSingleByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetValidationById extends IGet
{
    id?: number;
}

export interface IPatientGetValidationByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetCollection extends IGet
{
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupId extends IGet
{
    masterGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupId extends IGet
{
    collaboratingGroupId?: number;
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryId extends IGet
{
    countryId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionId extends IGet
{
    institutionId?: number;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByMasterGroupCode extends IGet
{
    masterGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCollaboratingGroupCode extends IGet
{
    collaboratingGroupCode: string;
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByCountryCode extends IGet
{
    countryCode: string;
    patientStateIds: number[];
}

export interface IPatientGetCollectionByInstitutionCode extends IGet
{
    institutionCode: string;
    patientStateIds: number[];
}

// @DataContract
export class FileUploadTemporary implements IFileUploadTemporary
{
    // @DataMember(Order=1)
    public guid: string;

    // @DataMember(Order=2)
    public name: string;

    // @DataMember(Order=3)
    public extension: string;

    // @DataMember(Order=4)
    public fileSize?: number;

    // @DataMember(Order=5)
    public contentType: string;

    // @DataMember(Order=6)
    public image: boolean;

    public constructor(init?: Partial<FileUploadTemporary>) { (Object as any).assign(this, init); }
}

export class File
{
    public id?: number;
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;
    public propertyName: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    // @Ignore()
    public upload: FileUploadTemporary;

    // @Ignore()
    public extension: string;

    // @Ignore()
    public fileSize?: number;

    // @Ignore()
    public contentType: string;

    // @Ignore()
    public exists: boolean;

    // @Ignore()
    public image: boolean;

    public constructor(init?: Partial<File>) { (Object as any).assign(this, init); }
}

export class SourceDocument implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocument>) { (Object as any).assign(this, init); }
}

export class Consent implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantSignedAndDatedConsentForm?: number;
    public dateOfBirth?: string;
    public dateParticipantSignedConsentForm?: string;
    public optionalTissueBankingConsentLevel?: number;
    public serviceAustraliaConsentLevel?: number;
    public participantAgreesToSA?: number;
    public consentFormVersion: string;
    public mainConsentDate?: string;
    public mainConsentCurrentTMDVersion?: number;
    public optionalConsentVersion: string;
    public optionalConsentDate?: string;
    public optionalConsentCurrentTMDVersion?: number;
    public optionalConsentSecondVersion: string;
    public optionalConsentSecondDate?: string;
    public optionalConsentCurrentSecondTMDVersion?: number;
    public servicesAustraliaConsentVersion: string;
    public servicesAustraliaConsentDate?: string;
    public servicesAustraliaConsentCurrentTMDVersion?: number;
    public currentPicfsApproved?: number;
    public patientSignedPicfs?: number;
    public allPagesPresent?: number;
    public investigatorSigned?: number;
    public saConsentFullyChecked?: number;
    public personWhoSignedAuth?: number;
    public deleteMainConsent?: number;
    public deleteOptionalConsent?: number;
    public deleteOptionalSecondConsent?: number;
    public deleteSAConsent?: number;
    public nameOfPersonWhoSigned: string;
    public ifNoToAnyAboveComment: string;
    public comments: string;
    public reviewedBy: string;
    public reviewedDate?: string;
    public consentStatus?: ConsentStatusType;
    // @Ignore()
    public servicesAustraliaRequired: boolean;

    // @Ignore()
    public saveButtonClicked?: boolean;

    // @Ignore()
    public sdv?: boolean;

    // @Ignore()
    public participantUsedEConsent?: boolean;

    // @Ignore()
    public validFrom: string;

    // @Ignore()
    public validTo: string;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public sourceDocuments: SourceDocument[];

    public constructor(init?: Partial<Consent>) { (Object as any).assign(this, init); }
}

export enum UkScenarioType
{
    ParticipantWithdrawnFromTrial = 1,
    ParticipantFoundNotToMeetEligibilityCriteria = 2,
    ParticipantDeceased = 3,
    OneOrMoreTumourSamplesERNegative = 4,
    AllTumourSamplesUnsuitableForTesting = 5,
    TreatmentToBeAllocatedAccordingToProtocol = 6,
    ParticipantWithdrawnFromTreatmentAllocation = 7,
}

export enum UkTreatmentType
{
    EndocrineTherapyAlone = 1,
    ChemotherapyFollowedByEndocrineTherapy = 2,
    IneligibleForTreatmentAllocationByTheTrial = 3,
    NoTreatmentAllocatedBecauseParticipantWithdrawnFromTrial = 4,
    NoTreatmentAllocatedBecauseParticipantFoundNotToMeetEligibilityCriteria = 5,
    NoTreatmentAllocatedBecauseParticipantDeceased = 6,
    NoTreatmentAllocationBecauseParticipantWithdrawnFromTreatmentAllocation = 7,
}

export enum TrialArm
{
    Control = 1,
    TestDirected = 2,
}

export class Patient implements IPatient, IRandomisationPatient
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Data.Institution)")
    public institutionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.PatientState)")
    public patientStateId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.Treatment)")
    public treatmentId?: number;

    public studyNumber: string;
    public prerandomisationNumber: string;
    public institutionCode: string;
    public email?: string;
    public account?: boolean;
    public initials: string;
    public dateOfBirth?: string;
    public sex?: number;
    public patientStatusReason?: string;
    // @Ignore()
    public consentForm: Consent;

    // @Ignore()
    public screeningLog: ScreeningLog;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public randomisationNumber: string;
    public randomisationDate?: string;
    public treatmentAllocationDate?: string;
    public ukScenario?: UkScenarioType;
    public ukTreatment?: UkTreatmentType;
    public trialArm?: TrialArm;

    public constructor(init?: Partial<Patient>) { (Object as any).assign(this, init); }
}

export interface IEventGetSingleById extends IGet
{
    id?: number;
}

export interface IEventGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
}

export interface IEventGetCollection extends IGet
{
}

export interface IEventGetCollectionByPatientId extends IGet
{
    patientId?: number;
}

export interface IEventGetCollectionByPatientStudyNumber extends IGet
{
    patientStudyNumber: string;
}

export class Event implements IEvent
{
    public id?: number;
    public patientId?: number;
    public eventDefinitionId?: number;
    public repeat?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Event>) { (Object as any).assign(this, init); }
}

export interface IFormGetSingleById extends IGet
{
    id?: number;
}

export interface IFormGetSingleByIds extends IGet
{
    institutionId?: number;
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetSingleByCodes extends IGet
{
    institutionCode: string;
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
    formRepeat?: number;
    createPatient?: boolean;
    createEvent?: boolean;
    createForm?: boolean;
}

export interface IFormGetCollection extends IGet
{
}

export interface IFormGetCollectionByIds extends IGet
{
    patientId?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export interface IFormGetCollectionByCodes extends IGet
{
    patientStudyNumber: string;
    eventDefinitionCode: string;
    eventRepeat?: number;
}

export class HospitalService implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: string;
    public usedIn3Months?: number;
    public numberOfDays?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<HospitalService>) { (Object as any).assign(this, init); }
}

export class OutpatientService implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: number;
    public specifyType: string;
    public usedIn3Months?: number;
    public numberOfVisits?: number;
    public numberOfCalls?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OutpatientService>) { (Object as any).assign(this, init); }
}

export class Test implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public description: number;
    public specifyDescription: string;
    public number?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Test>) { (Object as any).assign(this, init); }
}

export class CommunityService implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public type: number;
    public specifyType: string;
    public usedIn3Months?: number;
    public numberOfClinicalVisits?: number;
    public numberOfHomeVisits?: number;
    public numberOfCalls?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CommunityService>) { (Object as any).assign(this, init); }
}

export class Medication implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public drugName: string;
    public dose: string;
    public frequency: string;
    public prescription?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Medication>) { (Object as any).assign(this, init); }
}

export class OtherExpense implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public description: string;
    public totalCost?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<OtherExpense>) { (Object as any).assign(this, init); }
}

export class Additional implements IProForm, IProFormNotComplete
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public comments: string;
    public employed?: number;
    public daysOffSickFromWork?: number;
    public reductionInIncome?: number;
    public financialLossDueToTreatment?: number;
    public familyFriendsSupport?: number;
    public hoursSpentHelping?: number;
    public timeOffWorkToSupport?: number;
    public totalTimeTakenOffWork?: number;
    public hospitalServices: HospitalService[];
    public outpatientServices: OutpatientService[];
    public tests: Test[];
    public communityServices: CommunityService[];
    public travelDistanceKm?: number;
    public spentOnParking?: number;
    public spentOnTransportFares?: number;
    public spentOnAccomodation?: number;
    public medications: Medication[];
    public payForPrescription?: number;
    public otherExpenses: OtherExpense[];
    public additionalComments: string;
    public dateCompleted?: string;
    public completed?: boolean;
    public ptDidNotComplete?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Additional>) { (Object as any).assign(this, init); }
}

export class Distress implements IProForm, IProFormNotComplete
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public distressRating?: number;
    public appearance?: boolean;
    public bathingOrDressing?: boolean;
    public breathingDifficulties?: boolean;
    public passingUrine?: boolean;
    public constipation?: boolean;
    public diarrhoea?: boolean;
    public eating?: boolean;
    public fatigue?: boolean;
    public swollen?: boolean;
    public gettingAround?: boolean;
    public highTemperature?: boolean;
    public indigestions?: boolean;
    public soreOrDryMouth?: boolean;
    public nauseaOrVomiting?: boolean;
    public pain?: boolean;
    public dryItchyOrSoreSkin?: boolean;
    public sleepingProblems?: boolean;
    public tinglingInHandsOrFeet?: boolean;
    public changeInTaste?: boolean;
    public hotFlushes?: boolean;
    public memoryOrConcentration?: boolean;
    public speechProblems?: boolean;
    public woundCareAfterSurgery?: boolean;
    public caringResponsibilities?: boolean;
    public financeWorkOrHousing?: boolean;
    public transportOrParking?: boolean;
    public questionsAboutIllness?: boolean;
    public communicationWithMedicalStaff?: boolean;
    public children?: boolean;
    public partner?: boolean;
    public relativesOrFriends?: boolean;
    public lonelinessOrIsolation?: boolean;
    public sadnessOrDepression?: boolean;
    public worryFearOrAnxiety?: boolean;
    public angerOrFrustration?: boolean;
    public difficultyMakingPlans?: boolean;
    public guilt?: boolean;
    public hopelessness?: boolean;
    public sexualConcern?: boolean;
    public lossOfFaithOrSpiritualConcern?: boolean;
    public lossOfMeaningOrPurpose?: boolean;
    public noPeaceOrPastRegrets?: boolean;
    public otherConcerns: string;
    public appearanceRanking?: number;
    public bathingOrDressingRanking?: number;
    public breathingDifficultiesRanking?: number;
    public passingUrineRanking?: number;
    public constipationRanking?: number;
    public diarrhoeaRanking?: number;
    public eatingRanking?: number;
    public fatigueRanking?: number;
    public swollenRanking?: number;
    public gettingAroundRanking?: number;
    public highTemperatureRanking?: number;
    public indigestionsRanking?: number;
    public soreOrDryMouthRanking?: number;
    public nauseaOrVomitingRanking?: number;
    public painRanking?: number;
    public dryItchyOrSoreSkinRanking?: number;
    public sleepingProblemsRanking?: number;
    public tinglingInHandsOrFeetRanking?: number;
    public changeInTasteRanking?: number;
    public hotFlushesRanking?: number;
    public memoryOrConcentrationRanking?: number;
    public speechProblemsRanking?: number;
    public woundCareAfterSurgeryRanking?: number;
    public caringResponsibilitiesRanking?: number;
    public financeWorkOrHousingRanking?: number;
    public transportOrParkingRanking?: number;
    public questionsAboutIllnessRanking?: number;
    public communicationWithMedicalStaffRanking?: number;
    public childrenRanking?: number;
    public partnerRanking?: number;
    public relativesOrFriendsRanking?: number;
    public lonelinessOrIsolationRanking?: number;
    public sadnessOrDepressionRanking?: number;
    public worryFearOrAnxietyRanking?: number;
    public angerOrFrustrationRanking?: number;
    public difficultyMakingPlansRanking?: number;
    public guiltRanking?: number;
    public hopelessnessRanking?: number;
    public sexualConcernRanking?: number;
    public lossOfFaithOrSpiritualConcernRanking?: number;
    public lossOfMeaningOrPurposeRanking?: number;
    public noPeaceOrPastRegretsRanking?: number;
    public dateCompleted?: string;
    public completed?: boolean;
    public ptDidNotComplete?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Distress>) { (Object as any).assign(this, init); }
}

export class Eq5d implements IProForm, IProFormNotComplete
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public mobilityId?: number;
    public selfCareId?: number;
    public usualActivitiesId?: number;
    public painAndDiscomfortId?: number;
    public anxietyAndDepressionId?: number;
    public dateCompleted?: string;
    public completed?: boolean;
    public ptDidNotComplete?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Eq5d>) { (Object as any).assign(this, init); }
}

export class FactB implements IProForm, IProFormNotComplete
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public lackOfEnergy?: number;
    public nausea?: number;
    public troubleMeetingFamilyNeeds?: number;
    public pain?: number;
    public negativeSideEffects?: number;
    public feelsIll?: number;
    public spendsTimeInBed?: number;
    public closeToFriends?: number;
    public emotionalSupportFromFamily?: number;
    public supportFromFriends?: number;
    public familyAcceptsIllness?: number;
    public satisfiedWithFamilyCommunication?: number;
    public feelCloseToPartner?: number;
    public satisfiedWithSexLifeDeclined?: boolean;
    public satisfiedWithSexLife?: number;
    public sad?: number;
    public copingIsSatisfactory?: number;
    public losingHope?: number;
    public nervous?: number;
    public worriedAboutDeath?: number;
    public worriedConditionWillGetWorse?: number;
    public ableToWork?: number;
    public fulfillingWork?: number;
    public ableToEnjoyLife?: number;
    public acceptedIllness?: number;
    public sleepingWell?: number;
    public enjoyingUsualActivities?: number;
    public contentWithLife?: number;
    public shortOfBreath?: number;
    public selfconsciousAppearance?: number;
    public armsSwollenOrTender?: number;
    public feelsSexuallyAttractive?: number;
    public botheredByHairLoss?: number;
    public worriedFamilyWillGetSameIllness?: number;
    public worryStressImpactsIllness?: number;
    public botheredByWeightChange?: number;
    public feelLikeAWoman?: number;
    public experiencePainInPartsOfBody?: number;
    public dateCompleted?: string;
    public completed?: boolean;
    public ptDidNotComplete?: boolean;
    public sourceDocuments: SourceDocument[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<FactB>) { (Object as any).assign(this, init); }
}

export class ConsentHistory implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantSignedAndDatedConsentForm?: number;
    public dateOfBirth?: string;
    public dateParticipantSignedConsentForm?: string;
    public optionalTissueBankingConsentLevel?: number;
    public serviceAustraliaConsentLevel?: number;
    public participantAgreesToSA?: number;
    public consentFormVersion: string;
    public mainConsentDate?: string;
    public mainConsentCurrentTMDVersion?: number;
    public optionalConsentVersion: string;
    public optionalConsentDate?: string;
    public optionalConsentCurrentTMDVersion?: number;
    public optionalConsentSecondVersion: string;
    public optionalConsentSecondDate?: string;
    public optionalConsentCurrentSecondTMDVersion?: number;
    public servicesAustraliaConsentVersion: string;
    public servicesAustraliaConsentDate?: string;
    public servicesAustraliaConsentCurrentTMDVersion?: number;
    public currentPicfsApproved?: number;
    public patientSignedPicfs?: number;
    public allPagesPresent?: number;
    public investigatorSigned?: number;
    public saConsentFullyChecked?: number;
    public personWhoSignedAuth?: number;
    public nameOfPersonWhoSigned: string;
    public ifNoToAnyAboveComment: string;
    public comments: string;
    public reviewedBy: string;
    public reviewedDate?: string;
    public consentStatus?: ConsentStatusType;
    // @Ignore()
    public servicesAustraliaRequired: boolean;

    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public validFrom: string;
    public validTo: string;
    public deleteMainConsent?: number;
    public deleteOptionalConsent?: number;
    public deleteOptionalSecondConsent?: number;
    public deleteSAConsent?: number;

    public constructor(init?: Partial<ConsentHistory>) { (Object as any).assign(this, init); }
}

export class ProcomidaIncomplete implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public formDefinitionId?: number;
    public patientRefusal?: boolean;
    public patientIll?: boolean;
    public other?: boolean;
    public otherSpecify: string;
    public automaticallyMarkedIncomplete?: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProcomidaIncomplete>) { (Object as any).assign(this, init); }
}

export class ProcomidaAssistance implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public formDefinitionId?: number;
    public assistanceReadAllowed?: boolean;
    public assistanceClarificationRequired?: boolean;
    public assistanceIndependentlyCompleted?: boolean;
    public assistanceOther?: boolean;
    public reasonCouldNotRead?: boolean;
    public reasonOther?: boolean;
    public reasonOtherSpecify: string;
    public assistedByStaff?: boolean;
    public assistedByFamily?: boolean;
    public assistedByFriend?: boolean;
    public assistedByOther?: boolean;
    public assistedByOtherSpecify: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProcomidaAssistance>) { (Object as any).assign(this, init); }
}

export class Procomida implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public completedId?: number;
    public assistanceId?: number;
    public incompleteForms: ProcomidaIncomplete[];
    public assistedForms: ProcomidaAssistance[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Procomida>) { (Object as any).assign(this, init); }
}

export class TissueSample implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public sampleNumber?: number;
    public sampleType?: number;
    public dateCollected?: string;
    public ffpeBlockOrSlides?: number;
    public dateSlidesWereCut?: string;
    public tumourSampleId: string;
    public slideIdStart: string;
    public slideIdEnd: string;
    public tumourTissueOrigin?: number;
    public invasiveTumourSize?: number;
    public numberOfInvolvedNodes?: number;
    public sourceDocuments: SourceDocument[];
    public histopathologyReports?: number;
    public shipmentStatus?: number;
    public trackingId: string;
    public dateShipped?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TissueSample>) { (Object as any).assign(this, init); }
}

// @Flags()
export enum AssayResultPdfError
{
    None = 0,
    SampleIdDoesNotMatch = 1,
    NoReportDate = 2,
    NoAssayResult = 4,
    NoSubtype = 8,
    NoRiskCategory = 16,
    NoRorScore = 32,
    BadFile = 64,
}

export class ProsignaResult implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public tissueSampleId?: number;
    // @Ignore()
    public tissueSample: TissueSample;

    public status?: number;
    public centralLabSampleId: string;
    public dateLabReceived?: string;
    public dateResultExpected?: string;
    public specifyNewExpectedDate?: boolean;
    public newExpectedDateReason: string;
    public newExpectedDate?: string;
    public numberOfHAndEAssessments?: number;
    public numberOfRnaExtractions?: number;
    public tumourSampleSuitable?: number;
    public tumourSampleNotSuitableReason?: number;
    public tumourSampleNotSuitableReasonSpecify: string;
    public numberOfProsignaAssays?: number;
    public prosignaAssaySuccessful?: number;
    public prosignaAssayNotSuccessfulReason: string;
    public dateProsignaCompleted?: string;
    public prosignaAssayResultsSubtype?: number;
    public prosignaAssayResultsRorScore?: number;
    public prosignaAssayResultsRiskCategory?: number;
    public suitableForBankingAndFutureResearch?: number;
    public numberOfRemainingHAndEAssessments?: number;
    public numberOfRemainingRnaExtractions?: number;
    public sourceDocuments: SourceDocument[];
    public assayResultPdfError?: AssayResultPdfError;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProsignaResult>) { (Object as any).assign(this, init); }
}

export class ProsignaAssayResult implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public status?: number;
    public results: ProsignaResult[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ProsignaAssayResult>) { (Object as any).assign(this, init); }
}

export class ShipmentSample implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public shipmentSampleNumber?: number;
    public tissueSampleId?: number;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<ShipmentSample>) { (Object as any).assign(this, init); }
}

export class TissueSampleShipment implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public shipmentNumber?: number;
    public shipmentStatus?: number;
    public trackingId: string;
    public dateShipped?: string;
    public shipmentSamples: ShipmentSample[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TissueSampleShipment>) { (Object as any).assign(this, init); }
}

export class TissueSampleSubmission implements IForm
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    // @Ignore()
    public prosignaAssayResultStatus?: number;

    public tissueSamples: TissueSample[];
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<TissueSampleSubmission>) { (Object as any).assign(this, init); }
}

export interface IPatientGetEventStatesById extends IGet
{
    id?: number;
}

export interface IPatientGetEventStatesByStudyNumber extends IGet
{
    studyNumber: string;
}

export interface IPatientGetFormStatesByIds extends IGet
{
    id?: number;
    eventDefinitionId?: number;
    eventRepeat?: number;
}

export enum Permission
{
    OpmsAccess = 1,
    OpmsInstitutionView = 2,
    OpmsAdminister = 3,
    OpmsPatientView = 4,
    SystemAdminister = 5,
    OpmsPatientUpdate = 6,
    OpmsMasterGroupAdminister = 7,
    OpmsCoordinatingGroupAdminister = 8,
    PathologyTestAdminister = 9,
    BlockShipmentDetailsView = 10,
    OpmsPatientRegister = 11,
    OpmsPatientRandomise = 12,
    SaeView = 13,
    SaeReview = 14,
    ScreeningLogView = 15,
    ScreeningLogUpdate = 16,
    TeleformView = 17,
    TeleformManage = 18,
    EcrfView = 19,
    SaeAdminister = 20,
    MedicalReviewAccess = 21,
    MedicalReviewPerform = 22,
    MedicalReviewAdminister = 23,
    HistopathologyAccess = 24,
    DrugManagementView = 25,
    DrugManagementAdminister = 26,
    DrugManagementDispenseDrug = 27,
    OpmsProView = 28,
    OpmsProUpdate = 29,
    OpmsProAdminister = 30,
    OpmsPatientImpersonate = 31,
    OpmsPatientAccountManagement = 32,
    OpmsCrfView = 33,
    OpmsCrfUpdate = 34,
    OpmsEventView = 35,
    OpmsEventUpdate = 36,
    OpmsConsentAdminister = 37,
    OpmsCoordinatingGroupContactView = 38,
    OpmsSaeInvestigatorNotify = 39,
    EConsentView = 40,
    EConsentAdminister = 41,
    EConsentManage = 42,
    PISignOffView = 43,
    PISignOffPerform = 44,
    PISignOffAdminister = 45,
    OpmsDataManage = 46,
    RedactVerificationAccess = 47,
    ReconsentAdminister = 48,
}

export class EmailAddress
{
    public displayName: string;
    public address: string;

    public constructor(init?: Partial<EmailAddress>) { (Object as any).assign(this, init); }
}

export enum PatientStateType
{
    AwaitingRandomisation = 1,
    Randomised = 2,
    ThreeMonthFollowUp = 3,
    SixMonthFollowUp = 4,
    TwelveMonthFollowUp = 5,
    TwentyFourMonthFollowUp = 6,
    Ineligible = 7,
    Removed = 8,
}

export enum EventDefinitionType
{
    Consent = 1,
    TissueSample = 2,
    Baseline = 5,
    ThreeMonthFollowUp = 6,
    SixMonthFollowUp = 7,
    TwelveMonthFollowUp = 8,
    TwentyFourMonthFollowUp = 9,
    Staff = 10,
}

export enum FormDefinitionType
{
    Consent = 1,
    TissueSampleSubmission = 2,
    TissueSample = 3,
    TissueSampleTracking = 4,
    ProsignaAssayResult = 5,
    ProsignaResult = 6,
    Eq5d5l = 7,
    FactB = 8,
    Distress = 9,
    Additional = 10,
    SourceDocument = 11,
    HospitalService = 12,
    OutpatientService = 13,
    Test = 14,
    CommunityService = 15,
    Medication = 16,
    OtherExpense = 17,
    Procomida = 18,
    ProcomidaAssistance = 19,
    ProcomidaIncomplete = 20,
    TissueSampleShipment = 21,
    ShipmentSample = 22,
    ConsentHistory = 23,
}

export enum TreatmentType
{
    EndocrineTherapyAlone = 1,
    ChemotherapyFollowedByEndocrineTherapy = 2,
}

export enum LookupAbilityType
{
    NoProblem = 1,
    ModerateProblem = 2,
    Unable = 3,
}

export enum LookupAgreeType
{
    NotAtAll = 0,
    Little = 1,
    SomeWhat = 2,
    QuiteABit = 3,
    VeryMuch = 4,
}

export enum LookupYesNoType
{
    No = 0,
    Yes = 1,
}

export enum LookupScreeningLogReasonType
{
    ParticipantIneligible = 1,
    ParticipantNotApproached = 2,
    ParticipantDidNotWantChemotherapy = 3,
    ParticipantWantedChemotherapy = 4,
    ParticipantWantedToPayMultiparamTest = 5,
    ParticipantDidNotWantToWait = 6,
    Other = 7,
}

export enum LookupGenderType
{
    Female = 1,
    Male = 2,
    NonBinary = 3,
}

export enum LookupConsentType
{
    Consented = 1,
    DidNotConsent = 2,
}

export enum LookupBlockSlidesType
{
    Block = 1,
    Slides = 2,
}

export enum LookupTumourSampleNotSuitableReasonType
{
    InsufficientQuantity = 1,
    Other = 2,
}

export enum OutpatientServiceType
{
    HospitalDoctor = 1,
    HospitalNurse = 2,
    Physiotherapist = 3,
    Other = 9,
}

export enum TestType
{
    Mammogram = 1,
    Xray = 2,
    CTScan = 3,
    Ultrasound = 4,
    MRIScan = 5,
    BoneScan = 6,
    Other = 9,
}

export enum CommunityServiceType
{
    GP = 1,
    Nurse = 2,
    PsychiatristOrPsychologist = 3,
    Physiotherapist = 4,
    Other = 9,
}

export enum LookupShipmentStatusType
{
    Pending = 1,
    Shipped = 2,
}

export enum ProsignaAssayResultStatusType
{
    Pending = 1,
    SampleNotSuitable = 2,
    Unsuccessful = 3,
    SuccessfulAndLuminal = 4,
    SuccessfulAndNonLuminal = 5,
    NoTissueAvailable = 6,
}

export class SourceDocuments
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public name: string;
    public fileTypeId?: number;
    public file: File;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<SourceDocuments>) { (Object as any).assign(this, init); }
}

export class ConsentHistorySDV
{
    public id?: number;
    public patientId?: number;
    public eventId?: number;
    public parentFormDefinitionId?: number;
    public parentFormId?: number;
    public repeat?: number;
    public participantSignedAndDatedConsentForm?: number;
    public dateOfBirth?: string;
    public dateParticipantSignedConsentForm?: string;
    public optionalTissueBankingConsentLevel?: number;
    public serviceAustraliaConsentLevel?: number;
    public participantAgreesToSA?: number;
    public consentFormVersion: string;
    public mainConsentDate?: string;
    public mainConsentCurrentTMDVersion?: number;
    public optionalConsentVersion: string;
    public optionalConsentDate?: string;
    public optionalConsentCurrentTMDVersion?: number;
    public optionalConsentSecondVersion: string;
    public optionalConsentSecondDate?: string;
    public optionalConsentCurrentSecondTMDVersion?: number;
    public servicesAustraliaConsentVersion: string;
    public servicesAustraliaConsentDate?: string;
    public servicesAustraliaConsentCurrentTMDVersion?: number;
    public currentPicfsApproved?: number;
    public patientSignedPicfs?: number;
    public allPagesPresent?: number;
    public investigatorSigned?: number;
    public saConsentFullyChecked?: number;
    public personWhoSignedAuth?: number;
    public nameOfPersonWhoSigned: string;
    public ifNoToAnyAboveComment: string;
    public comments: string;
    public reviewedBy: string;
    public reviewedDate?: string;
    public consentStatus?: ConsentStatusType;
    public servicesAustraliaRequired: boolean;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;
    public validFrom: string;
    public validTo: string;
    public deleteMainConsent?: number;
    public deleteOptionalConsent?: number;
    public deleteOptionalSecondConsent?: number;
    public deleteSAConsent?: number;
    // @Ignore()
    public sourceDocuments: SourceDocuments[];

    public constructor(init?: Partial<ConsentHistorySDV>) { (Object as any).assign(this, init); }
}

export class OverdueProcomidaRow
{
    public studyNumber: string;
    public formOverdueName: string;
    public expectedCompletionDate: string;

    public constructor(init?: Partial<OverdueProcomidaRow>) { (Object as any).assign(this, init); }
}

export class ProsignaAssayEmailRow
{
    public patientId: string;
    public initials: string;
    public siteName: string;
    public dateOfRandomisation: string;
    public trialArm: string;
    public tissueId: string;
    public preSurgicalEndocrineGiven?: boolean;
    public dateSampleShipped: string;
    public dateRecievedAtCentralLab: string;

    public constructor(init?: Partial<ProsignaAssayEmailRow>) { (Object as any).assign(this, init); }
}

export class ProsignaResultsReportData
{
    public patientId: string;
    public institution: string;
    public centralLabSampleId: string;
    public tumourSampleId: string;
    public dateOfRandomisation: string;
    public randomisationArm: string;
    public dateSentToLab: string;
    public resultStatus: string;
    public url: string;

    public constructor(init?: Partial<ProsignaResultsReportData>) { (Object as any).assign(this, init); }
}

export class MissingSourceDocumentRow
{
    public institution: string;
    public participantId: string;
    public missingConsentForm: boolean;
    public missingOptionalConsentForm: boolean;
    public missingServicesAustraliaConsentForm: boolean;
    public econsentMissingOptionalConsentForm: boolean;
    public econsentMissingServicesAustraliaConsentForm: boolean;
    public missingHistopathReport: boolean;

    public constructor(init?: Partial<MissingSourceDocumentRow>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UserApiKey
{
    // @DataMember(Order=1)
    public key: string;

    // @DataMember(Order=2)
    public keyType: string;

    // @DataMember(Order=3)
    public expiryDate?: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UserApiKey>) { (Object as any).assign(this, init); }
}

export enum ValidationResultType
{
    Valid = 1,
    Ineligible = 2,
    Error = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export enum ValidationErrorType
{
    Warning = 1,
    Ineligible = 2,
    Normal = 3,
    StratificationFailure = 4,
    Critical = 5,
}

export class ScreeningLogFormValidationError implements IScreeningLogFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<ScreeningLogFormValidationError>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResult
{
    public result: ValidationResultType;
    public errors: ScreeningLogFormValidationError[];

    public constructor(init?: Partial<ScreeningLogFormValidationResult>) { (Object as any).assign(this, init); }
}

export class PicfType
{
    public id?: number;
    public name: string;
    public label: string;
    public fileName: string;

    public constructor(init?: Partial<PicfType>) { (Object as any).assign(this, init); }
}

export class Picf
{
    public type: PicfType;
    public picfId?: number;
    public name: string;
    public label: string;
    public version: string;
    public fileName: string;
    public file: string;

    public constructor(init?: Partial<Picf>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ResponseError
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public fieldName: string;

    // @DataMember(Order=3)
    public message: string;

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseError>) { (Object as any).assign(this, init); }
}

export interface IFileUploadTemporary
{
    guid: string;
    name: string;
    extension: string;
    fileSize?: number;
    contentType: string;
    image: boolean;
}

export enum UserType
{
    TrialPersonnel = 1,
    Patient = 2,
}

export class LookupItem implements ILookupItem
{
    public id?: number;
    public value: string;
    public order?: number;
    public type?: number;

    public constructor(init?: Partial<LookupItem>) { (Object as any).assign(this, init); }
}

export class Lookup
{
    public propertyName: string;
    public items: LookupItem[];

    public constructor(init?: Partial<Lookup>) { (Object as any).assign(this, init); }
}

export class FormMetadata
{
    public formDefinitionId?: number;
    public formDefinitionCode: string;
    public name: string;
    public propertyName: string;
    public title: string;
    public instructions: string;

    public constructor(init?: Partial<FormMetadata>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinitionState implements IEventDefinitionFormDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinitionFormDefinition)")
    public eventDefinitionFormDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<EventDefinitionFormDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionFormDefinition
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public states: EventDefinitionFormDefinitionState[];

    public constructor(init?: Partial<EventDefinitionFormDefinition>) { (Object as any).assign(this, init); }
}

export class EventDefinitionState implements IEventDefinitionState
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public patientStateId?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;

    public constructor(init?: Partial<EventDefinitionState>) { (Object as any).assign(this, init); }
}

export class EventDefinitionErrorMessage implements IEventDefinitionErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.EventDefinition)")
    public eventDefinitionId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventDefinitionErrorMessage>) { (Object as any).assign(this, init); }
}

export class EventDefinition
{
    public id?: number;
    public name: string;
    public code: string;
    public repeating?: boolean;
    public minimumRepeat?: number;
    public maximumRepeat?: number;
    public order?: number;
    public formDefinitions: EventDefinitionFormDefinition[];
    public states: EventDefinitionState[];
    public errorMessages: EventDefinitionErrorMessage[];

    public constructor(init?: Partial<EventDefinition>) { (Object as any).assign(this, init); }
}

export enum FormType
{
    Crf = 1,
    Pro = 2,
    Staff = 3,
}

export class FormDefinitionPropertyErrorMessage implements IFormDefinitionPropertyErrorMessage
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinitionProperty)")
    public formDefinitionPropertyId?: number;

    public errorCode: string;
    public message: string;
    public detailedMessage: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormDefinitionPropertyErrorMessage>) { (Object as any).assign(this, init); }
}

export class FormDefinitionProperty
{
    public id?: number;
    // @References("typeof(NewtonGreen.ServiceStack.Opms.Models.Configuration.FormDefinition)")
    public formDefinitionId?: number;

    public propertyName: string;
    public label: string;
    public detailedLabel: string;
    public errorMessages: FormDefinitionPropertyErrorMessage[];

    public constructor(init?: Partial<FormDefinitionProperty>) { (Object as any).assign(this, init); }
}

export class FormDefinition
{
    public id?: number;
    public code: string;
    public type?: FormType;
    public name: string;
    public instructions: string;
    public patientInstructions: string;
    public properties: FormDefinitionProperty[];
    // @Ignore()
    public subformProperties: { [index: string]: FormDefinitionProperty[]; };

    public constructor(init?: Partial<FormDefinition>) { (Object as any).assign(this, init); }
}

export class PatientState implements IPatientState
{
    public id?: number;
    public name: string;
    public order?: number;

    public constructor(init?: Partial<PatientState>) { (Object as any).assign(this, init); }
}

export class Treatment implements ITreatment
{
    public id?: number;
    public name: string;

    public constructor(init?: Partial<Treatment>) { (Object as any).assign(this, init); }
}

export class MasterGroup implements IMasterGroup
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<MasterGroup>) { (Object as any).assign(this, init); }
}

export class CollaboratingGroup implements ICollaboratingGroup
{
    public id?: number;
    public masterGroupId?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<CollaboratingGroup>) { (Object as any).assign(this, init); }
}

export class Country implements ICountry
{
    public id?: number;
    public code: string;
    public name: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Country>) { (Object as any).assign(this, init); }
}

export class Institution implements IInstitution
{
    public id?: number;
    public countryId?: number;
    public masterGroupId?: number;
    public collaboratingGroupId?: number;
    public code: string;
    public name: string;
    public enabled?: boolean;
    public activatedDate?: string;
    public recruitmentDisabled?: boolean;
    public eConsentActivationDate?: string;
    public enteredDate?: string;
    public enteredBy: string;
    public modifiedDate?: string;
    public modifiedBy: string;

    public constructor(init?: Partial<Institution>) { (Object as any).assign(this, init); }
}

export class PatientValidationError implements IPatientValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<PatientValidationError>) { (Object as any).assign(this, init); }
}

export class EventValidationError implements IEventValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<EventValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationError implements IFormValidationError
{
    public property: string;
    public message: string;
    public detailedMessage: string;
    public code: string;
    public ruleSet: string;
    public type?: ValidationErrorType;

    public constructor(init?: Partial<FormValidationError>) { (Object as any).assign(this, init); }
}

export class FormValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public formId?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public completed?: boolean;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;
    public errors: FormValidationError[];

    public constructor(init?: Partial<FormValidationResult>) { (Object as any).assign(this, init); }
}

export class EventValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public eventId?: number;
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;
    public errors: EventValidationError[];
    public formResults: FormValidationResult[];

    public constructor(init?: Partial<EventValidationResult>) { (Object as any).assign(this, init); }
}

export class PatientValidationResult
{
    public result: ValidationResultType;
    public answeredQuestions?: number;
    public totalQuestions?: number;
    public patientId?: number;
    public patientDefinitionId?: number;
    public patientRepeat?: number;
    public errors: PatientValidationError[];
    public eventResults: EventValidationResult[];

    public constructor(init?: Partial<PatientValidationResult>) { (Object as any).assign(this, init); }
}

export class EventState implements IEventState
{
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<EventState>) { (Object as any).assign(this, init); }
}

export class FormState implements IFormState
{
    public eventDefinitionId?: number;
    public eventRepeat?: number;
    public formDefinitionId?: number;
    public formRepeat?: number;
    public visible?: boolean;
    public read?: boolean;
    public write?: boolean;
    public validate?: boolean;
    public redactable?: boolean;

    public constructor(init?: Partial<FormState>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnel
{
    public id?: number;
    public name: string;
    public email: string;

    public constructor(init?: Partial<InstitutionPersonnel>) { (Object as any).assign(this, init); }
}

export interface IScreeningLog extends IDataModel
{
    institutionId?: number;
    patientId?: number;
    screeningDate?: string;
    outcome?: ScreeningOutcome;
    status?: ScreeningStatus;
    initials: string;
    dateOfBirth?: string;
    reason?: number;
    reasonSpecify: string;
}

export interface IDataModel extends IModel, IHasAudit
{
}

export interface IModel
{
    id?: number;
}

export interface IHasAudit
{
    enteredDate?: string;
    enteredBy: string;
    modifiedDate?: string;
    modifiedBy: string;
}

export interface IEoi extends IDataModel
{
    // @References("typeof(NewtonGreen.ServiceStack.Opms.EConsent.Models.Dos.Invitation)")
    invitationId?: number;

    firstName: string;
    lastName: string;
    confirmed?: boolean;
    dateSubmitted?: string;
    statusId?: StatusTypeEnum;
    invitation: Invitation;
}

export interface IPatientConsent extends IDataModel
{
    eoiId?: number;
    typeId?: ConsentTypeEnum;
    statusId?: StatusTypeEnum;
    guid: string;
    dateStarted?: string;
    mobileNumber: string;
    patientSigned?: boolean;
    datePatientSigned?: string;
    investigatorSigned?: boolean;
    dateInvestigatorSigned?: string;
    dateNotificationSent?: string;
    type: ConsentType;
    status: StatusType;
    eoi: IEoi;
    picfs: ConsentPicf[];
}

export interface IPatient extends IDataModel, IHasInstitutionId, IHasPatientStateId, IHasTreatmentId
{
    studyNumber: string;
}

export interface IHasInstitutionId
{
    institutionId?: number;
}

export interface IHasPatientStateId
{
    patientStateId?: number;
}

export interface IHasTreatmentId
{
    treatmentId?: number;
}

export interface IRandomisationPatient
{
    randomisationNumber: string;
    randomisationDate?: string;
}

export interface IEvent extends IDataModel, IHasPatientId, IHasEventDefinitionId
{
    repeat?: number;
}

export interface IHasPatientId
{
    patientId?: number;
}

export interface IHasEventDefinitionId
{
    eventDefinitionId?: number;
}

export interface IForm extends IDataModel, IHasPatientId, IHasEventId, IHasParentFormDefinitionId, IHasParentFormId
{
    repeat?: number;
}

export interface IHasEventId
{
    eventId?: number;
}

export interface IHasParentFormDefinitionId
{
    parentFormDefinitionId?: number;
}

export interface IHasParentFormId
{
    parentFormId?: number;
}

export interface IProForm extends IForm
{
    completed?: boolean;
}

export interface IProFormNotComplete
{
    ptDidNotComplete?: boolean;
}

export interface IScreeningLogFormValidationError extends IValidationError
{
    property: string;
}

export interface IScreeningLogFormValidationResult extends IValidationResult
{
    errors: IScreeningLogFormValidationError[];
}

export interface IValidationError
{
    message: string;
    detailedMessage: string;
    code: string;
    type?: ValidationErrorType;
}

export interface IValidationResult
{
    result: ValidationResultType;
    errors: IValidationError[];
}

export interface ILookupItem
{
    id?: number;
    value: string;
    order?: number;
    type?: number;
}

export interface ILookup
{
    propertyName: string;
    items: ILookupItem[];
}

export interface IEventDefinitionFormDefinitionState extends IConfigurationModel, IHasEventDefinitionFormDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IEventDefinitionFormDefinition extends IConfigurationModel, IHasEventDefinitionId, IHasFormDefinitionId
{
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    states: IEventDefinitionFormDefinitionState[];
}

export interface IEventDefinitionState extends IConfigurationModel, IHasEventDefinitionId, IHasPatientStateId
{
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
}

export interface IEventDefinitionErrorMessage extends IConfigurationModel, IHasEventDefinitionId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IEventDefinition extends IConfigurationModel
{
    name: string;
    code: string;
    repeating?: boolean;
    minimumRepeat?: number;
    maximumRepeat?: number;
    order?: number;
    formDefinitions: IEventDefinitionFormDefinition[];
    states: IEventDefinitionState[];
    errorMessages: IEventDefinitionErrorMessage[];
}

export interface IConfigurationModel extends IModel
{
}

export interface IFormDefinitionPropertyErrorMessage extends IConfigurationModel, IHasFormDefinitionPropertyId
{
    errorCode: string;
    message: string;
    detailedMessage: string;
    type?: ValidationErrorType;
}

export interface IFormDefinitionProperty extends IConfigurationModel, IHasFormDefinitionId
{
    propertyName: string;
    label: string;
    detailedLabel: string;
    errorMessages: IFormDefinitionPropertyErrorMessage[];
}

export interface IFormDefinition extends IConfigurationModel
{
    type?: FormType;
    name: string;
    code: string;
    instructions: string;
    patientInstructions: string;
    properties: IFormDefinitionProperty[];
    subformProperties: { [index: string]: IFormDefinitionProperty[]; };
}

export interface IPatientState extends IConfigurationModel
{
    name: string;
    order?: number;
}

export interface ITreatment extends IConfigurationModel
{
    name: string;
}

export interface IMasterGroup extends IDataModel
{
    name: string;
    code: string;
}

export interface ICollaboratingGroup extends IDataModel, IHasMasterGroupId
{
    name: string;
    code: string;
}

export interface IHasMasterGroupId
{
    masterGroupId?: number;
}

export interface ICountry extends IDataModel
{
    name: string;
    code: string;
}

export interface IInstitution extends IDataModel, IHasMasterGroupId, IHasCollaboratingGroupId, IHasCountryId
{
    name: string;
    code: string;
    enabled?: boolean;
    activatedDate?: string;
    recruitmentDisabled?: boolean;
    eConsentActivationDate?: string;
}

export interface IHasCollaboratingGroupId
{
    collaboratingGroupId?: number;
}

export interface IHasCountryId
{
    countryId?: number;
}

export interface IPatientValidationError extends IValidationError
{
}

export interface IEventValidationError extends IValidationError
{
}

export interface IFormValidationError extends IValidationError
{
    property: string;
}

export interface IFormValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    formId?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    completed?: boolean;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
    errors: IFormValidationError[];
}

export interface IEventValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    eventDefinitionId?: number;
    eventId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
    errors: IEventValidationError[];
    formResults: IFormValidationResult[];
}

export interface IPatientValidationResult extends IValidationResult
{
    answeredQuestions?: number;
    totalQuestions?: number;
    patientId?: number;
    errors: IPatientValidationError[];
    eventResults: IEventValidationResult[];
}

export interface IEventState
{
    eventDefinitionId?: number;
    eventRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IFormState
{
    eventDefinitionId?: number;
    eventRepeat?: number;
    formDefinitionId?: number;
    formRepeat?: number;
    visible?: boolean;
    read?: boolean;
    write?: boolean;
    validate?: boolean;
    redactable?: boolean;
}

export interface IFileUpload
{
    extension: string;
    fileSize?: number;
    contentType: string;
    exists: boolean;
    image: boolean;
    upload: IFileUploadTemporary;
}

export interface IFile extends IDataModel, IHasPatientId, IHasFormDefinitionId, IHasFormId, IFileUpload
{
    propertyName: string;
}

export interface IHasFormDefinitionId
{
    formDefinitionId?: number;
}

export interface IHasFormId
{
    formId?: number;
}

export interface IHasEventDefinitionFormDefinitionId
{
    eventDefinitionFormDefinitionId?: number;
}

export interface IHasFormDefinitionPropertyId
{
    formDefinitionPropertyId?: number;
}

// @DataContract
export class ResponseStatus
{
    // @DataMember(Order=1)
    public errorCode: string;

    // @DataMember(Order=2)
    public message: string;

    // @DataMember(Order=3)
    public stackTrace: string;

    // @DataMember(Order=4)
    public errors: ResponseError[];

    // @DataMember(Order=5)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<ResponseStatus>) { (Object as any).assign(this, init); }
}

export class SendTissueSampleFormCompletionRequestEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendTissueSampleFormCompletionRequestEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientWelcomeEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientWelcomeEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReminderResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SendPatientReminderResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetEnumerationTypesResponse
{
    public patientStateType?: PatientStateType;
    public eventDefinitionType?: EventDefinitionType;
    public formDefinitionType?: FormDefinitionType;
    public treatmentType?: TreatmentType;
    public lookupAbilityType?: LookupAbilityType;
    public lookupAgreeType?: LookupAgreeType;
    public lookupYesNoType?: LookupYesNoType;
    public lookupScreeningLogReasonType?: LookupScreeningLogReasonType;
    public lookupGenderType?: LookupGenderType;
    public lookupConsentType?: LookupConsentType;
    public lookupBlockSlidesType?: LookupBlockSlidesType;
    public lookupTumourSampleNotSuitableReasonType?: LookupTumourSampleNotSuitableReasonType;
    public outpatientServiceType: OutpatientServiceType;
    public testType: TestType;
    public communityServiceType: CommunityServiceType;
    public lookupShipmentStatusType?: LookupShipmentStatusType;
    public prosignaAssayResultStatusType?: ProsignaAssayResultStatusType;

    public constructor(init?: Partial<GetEnumerationTypesResponse>) { (Object as any).assign(this, init); }
}

export class ActiveDirectoryResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ActiveDirectoryResponse>) { (Object as any).assign(this, init); }
}

export class SendPatientNotFoundEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPatientNotFoundEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetConsentHistoryListByIdResponse
{
    public consentHistory: ConsentHistorySDV[];
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetConsentHistoryListByIdResponse>) { (Object as any).assign(this, init); }
}

export class ReviewConsentResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ReviewConsentResponse>) { (Object as any).assign(this, init); }
}

export class ConfirmProsignaResultResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConfirmProsignaResultResponse>) { (Object as any).assign(this, init); }
}

export class SendProsignaAssayResultEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendProsignaAssayResultEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendNoMoreTumourSampleAvailableEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendNoMoreTumourSampleAvailableEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetProsignaAssayResultStatusResponse
{
    public responseStatus: ResponseStatus;
    public status?: number;

    public constructor(init?: Partial<GetProsignaAssayResultStatusResponse>) { (Object as any).assign(this, init); }
}

export class SetProsignaAssayResultStatusResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SetProsignaAssayResultStatusResponse>) { (Object as any).assign(this, init); }
}

export class SendInsufficientTumourSampleEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendInsufficientTumourSampleEmailResponse>) { (Object as any).assign(this, init); }
}

export class GetEConsentSettingsResponse
{
    public eConsentActivated: boolean;
    public eConsentActivatedInstitutions: number[];
    public trialInfoPageUrl: string;
    public trialConsentPageUrl: string;
    public trialConsentSignedPageUrl: string;
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetEConsentSettingsResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvitationEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvitationEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvitationEmailCopyResponse
{
    public responseStatus: ResponseStatus;
    public exceptions: string[];
    public messages: string[];
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvitationEmailCopyResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentEoiEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentEoiEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentInvestigatorEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentInvestigatorEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentSuccessTrialEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendEConsentRefusedPatientEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendScreeningLogReminderEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendScreeningLogReminderEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendMissingSourceDocumentEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendMissingSourceDocumentEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendSiteRequestUploadNewConsentEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendSiteRequestUploadNewConsentEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendBCTNewConsentUploadEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendBCTNewConsentUploadEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendProsignaAssayEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendProsignaAssayEmailResponse>) { (Object as any).assign(this, init); }
}

export class SendOverdueProcomidaEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendOverdueProcomidaEmailResponse>) { (Object as any).assign(this, init); }
}

export class GenerateOverdueProcomidaEmailResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GenerateOverdueProcomidaEmailResponse>) { (Object as any).assign(this, init); }
}

export class ProcessEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;

    public constructor(init?: Partial<ProcessEmailResponse>) { (Object as any).assign(this, init); }
}

export class QueueNewEmailsToParseResponse
{
    public responseStatus: ResponseStatus;
    public count: number;

    public constructor(init?: Partial<QueueNewEmailsToParseResponse>) { (Object as any).assign(this, init); }
}

export class QueueReprocessEmailsToParseResponse
{
    public responseStatus: ResponseStatus;
    public count: number;

    public constructor(init?: Partial<QueueReprocessEmailsToParseResponse>) { (Object as any).assign(this, init); }
}

export class GetOverdueProcomidaResponse
{
    public responseStatus: ResponseStatus;
    public overdueProcomidaRows: OverdueProcomidaRow[];

    public constructor(init?: Partial<GetOverdueProcomidaResponse>) { (Object as any).assign(this, init); }
}

export class GetProsignaAssayEmailRowsResponse
{
    public responseStatus: ResponseStatus;
    public prosignaAssayEmailRow: ProsignaAssayEmailRow[];

    public constructor(init?: Partial<GetProsignaAssayEmailRowsResponse>) { (Object as any).assign(this, init); }
}

export class GetProsignaResultsReportDataResponse
{
    public responseStatus: ResponseStatus;
    public prosignaResultsData: ProsignaResultsReportData[];

    public constructor(init?: Partial<GetProsignaResultsReportDataResponse>) { (Object as any).assign(this, init); }
}

export class SendPathologyRequisitionFormEmailResponse
{
    public responseStatus: ResponseStatus;
    public result: string;
    public scheduled?: boolean;
    public subject: string;
    public toAddresses: EmailAddress[];
    public ccAddresses: EmailAddress[];
    public bccAddresses: EmailAddress[];
    public fromAddress: EmailAddress;

    public constructor(init?: Partial<SendPathologyRequisitionFormEmailResponse>) { (Object as any).assign(this, init); }
}

export class ExportPatientDataResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ExportPatientDataResponse>) { (Object as any).assign(this, init); }
}

export class GetMissingSourceDocumentsResponse
{
    public responseStatus: ResponseStatus;
    public missingSourceDocuments: MissingSourceDocumentRow[];

    public constructor(init?: Partial<GetMissingSourceDocumentsResponse>) { (Object as any).assign(this, init); }
}

export class GetTpRegistrationByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpRegistrationByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

export class GetTpScreeningByInstitutionIdResponse
{
    public data: { [index: string]: string; };
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetTpScreeningByInstitutionIdResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AuthenticateResponse implements IHasSessionId, IHasBearerToken
{
    // @DataMember(Order=1)
    public userId: string;

    // @DataMember(Order=2)
    public sessionId: string;

    // @DataMember(Order=3)
    public userName: string;

    // @DataMember(Order=4)
    public displayName: string;

    // @DataMember(Order=5)
    public referrerUrl: string;

    // @DataMember(Order=6)
    public bearerToken: string;

    // @DataMember(Order=7)
    public refreshToken: string;

    // @DataMember(Order=8)
    public profileUrl: string;

    // @DataMember(Order=9)
    public roles: string[];

    // @DataMember(Order=10)
    public permissions: string[];

    // @DataMember(Order=11)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=12)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AuthenticateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class UnAssignRolesResponse
{
    // @DataMember(Order=1)
    public allRoles: string[];

    // @DataMember(Order=2)
    public allPermissions: string[];

    // @DataMember(Order=3)
    public meta: { [index: string]: string; };

    // @DataMember(Order=4)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<UnAssignRolesResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class GetApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<GetApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class RegenerateApiKeysResponse
{
    // @DataMember(Order=1)
    public results: UserApiKey[];

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    // @DataMember(Order=3)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<RegenerateApiKeysResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLogs: ScreeningLog[];

    public constructor(init?: Partial<ScreeningLogCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ScreeningLogDeleteResponse>) { (Object as any).assign(this, init); }
}

export class ScreeningLogFormValidationResponse
{
    public responseStatus: ResponseStatus;
    public validationResult: ScreeningLogFormValidationResult;

    public constructor(init?: Partial<ScreeningLogFormValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ScreeningLogSingleByPatientIdResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogSingleByPatientIdResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetCollectionResponse
{
    public responseStatus: ResponseStatus;
    public invitations: Invitation[];

    public constructor(init?: Partial<InvitationGetCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByIdResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByIdResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetSingleByGuidResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationGetSingleByGuidResponse>) { (Object as any).assign(this, init); }
}

export class InvitationSaveResponse
{
    public responseStatus: ResponseStatus;
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSaveResponse>) { (Object as any).assign(this, init); }
}

export class InvitationDeleteResponse
{
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<InvitationDeleteResponse>) { (Object as any).assign(this, init); }
}

export class InvitationGetInvestigatorsResponse
{
    public responseStatus: ResponseStatus;
    public investigators: { [index: string]: string; };

    public constructor(init?: Partial<InvitationGetInvestigatorsResponse>) { (Object as any).assign(this, init); }
}

export class GetPicfsResponse
{
    public responseStatus: ResponseStatus;
    public picfs: Picf[];

    public constructor(init?: Partial<GetPicfsResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eoi: Eoi;

    public constructor(init?: Partial<EoiSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eois: Eoi[];

    public constructor(init?: Partial<EoiCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EoiDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<EoiDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientConsent: PatientConsent;

    public constructor(init?: Partial<PatientConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientConsents: PatientConsent[];

    public constructor(init?: Partial<PatientConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientConsentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<PatientConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

export class PatientConsentSendVerificationCodeResponse
{
    public responseStatus: ResponseStatus;
    public verificationCode: number;

    public constructor(init?: Partial<PatientConsentSendVerificationCodeResponse>) { (Object as any).assign(this, init); }
}

export class UploadResponse
{
    public responseStatus: ResponseStatus;
    public upload: IFileUploadTemporary;

    public constructor(init?: Partial<UploadResponse>) { (Object as any).assign(this, init); }
}

export class GetUserResponse
{
    public responseStatus: ResponseStatus;
    public userId: string;
    public sessionId: string;
    public userName: string;
    public displayName: string;
    public type?: UserType;

    public constructor(init?: Partial<GetUserResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class LookupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public lookups: Lookup[];

    public constructor(init?: Partial<LookupCollectionResponse>) { (Object as any).assign(this, init); }
}

export class GetFormMetadataResponse
{
    public responseStatus: ResponseStatus;
    public formMetadata: FormMetadata[];

    public constructor(init?: Partial<GetFormMetadataResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eventDefinition: EventDefinition;

    public constructor(init?: Partial<EventDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public eventDefinitions: EventDefinition[];

    public constructor(init?: Partial<EventDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public formDefinition: FormDefinition;

    public constructor(init?: Partial<FormDefinitionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormDefinitionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public formDefinitions: FormDefinition[];

    public constructor(init?: Partial<FormDefinitionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientState: PatientState;

    public constructor(init?: Partial<PatientStateSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientStateCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patientStates: PatientState[];

    public constructor(init?: Partial<PatientStateCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public treatment: Treatment;

    public constructor(init?: Partial<TreatmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TreatmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public treatments: Treatment[];

    public constructor(init?: Partial<TreatmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public masterGroup: MasterGroup;

    public constructor(init?: Partial<MasterGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MasterGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public masterGroups: MasterGroup[];

    public constructor(init?: Partial<MasterGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public collaboratingGroup: CollaboratingGroup;

    public constructor(init?: Partial<CollaboratingGroupSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CollaboratingGroupCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public collaboratingGroups: CollaboratingGroup[];

    public constructor(init?: Partial<CollaboratingGroupCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountrySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public country: Country;

    public constructor(init?: Partial<CountrySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CountryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public countries: Country[];

    public constructor(init?: Partial<CountryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public institution: Institution;

    public constructor(init?: Partial<InstitutionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class InstitutionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public institutions: Institution[];

    public constructor(init?: Partial<InstitutionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patient: Patient;

    public constructor(init?: Partial<PatientSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: PatientValidationResult;

    public constructor(init?: Partial<PatientValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class PatientCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public patients: Patient[];

    public constructor(init?: Partial<PatientCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public event: Event;

    public constructor(init?: Partial<EventSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public events: Event[];

    public constructor(init?: Partial<EventCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: SourceDocument[];

    public constructor(init?: Partial<SourceDocumentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<SourceDocumentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class SourceDocumentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<SourceDocumentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Additional;

    public constructor(init?: Partial<AdditionalSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Additional[];

    public constructor(init?: Partial<AdditionalCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<AdditionalDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class AdditionalValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<AdditionalValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommunityServiceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: CommunityService;

    public constructor(init?: Partial<CommunityServiceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommunityServiceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: CommunityService[];

    public constructor(init?: Partial<CommunityServiceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommunityServiceDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<CommunityServiceDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class CommunityServiceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<CommunityServiceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistressSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Distress;

    public constructor(init?: Partial<DistressSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistressCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Distress[];

    public constructor(init?: Partial<DistressCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistressDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<DistressDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class DistressValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<DistressValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5dSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Eq5d;

    public constructor(init?: Partial<Eq5dSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5dCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Eq5d[];

    public constructor(init?: Partial<Eq5dCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5dDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<Eq5dDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class Eq5dValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<Eq5dValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FactBSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: FactB;

    public constructor(init?: Partial<FactBSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FactBCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: FactB[];

    public constructor(init?: Partial<FactBCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FactBDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<FactBDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FactBValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<FactBValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HospitalServiceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: HospitalService;

    public constructor(init?: Partial<HospitalServiceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HospitalServiceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: HospitalService[];

    public constructor(init?: Partial<HospitalServiceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HospitalServiceDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<HospitalServiceDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class HospitalServiceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<HospitalServiceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicationSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Medication;

    public constructor(init?: Partial<MedicationSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicationCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Medication[];

    public constructor(init?: Partial<MedicationCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicationDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<MedicationDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class MedicationValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<MedicationValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherExpenseSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: OtherExpense;

    public constructor(init?: Partial<OtherExpenseSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherExpenseCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: OtherExpense[];

    public constructor(init?: Partial<OtherExpenseCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherExpenseDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<OtherExpenseDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OtherExpenseValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<OtherExpenseValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OutpatientServiceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: OutpatientService;

    public constructor(init?: Partial<OutpatientServiceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OutpatientServiceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: OutpatientService[];

    public constructor(init?: Partial<OutpatientServiceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OutpatientServiceDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<OutpatientServiceDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class OutpatientServiceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<OutpatientServiceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TestSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Test;

    public constructor(init?: Partial<TestSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TestCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Test[];

    public constructor(init?: Partial<TestCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TestDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TestDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TestValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TestValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Consent;

    public constructor(init?: Partial<ConsentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Consent[];

    public constructor(init?: Partial<ConsentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConsentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConsentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentHistorySingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ConsentHistory;

    public constructor(init?: Partial<ConsentHistorySingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentHistoryCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ConsentHistory[];

    public constructor(init?: Partial<ConsentHistoryCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentHistoryDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ConsentHistoryDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ConsentHistoryValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ConsentHistoryValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: Procomida[];

    public constructor(init?: Partial<ProcomidaCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistanceSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ProcomidaAssistance[];

    public constructor(init?: Partial<ProcomidaAssistanceCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaAssistanceValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaAssistanceValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompleteSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ProcomidaIncomplete[];

    public constructor(init?: Partial<ProcomidaIncompleteCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProcomidaIncompleteValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProcomidaIncompleteValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ProsignaAssayResult[];

    public constructor(init?: Partial<ProsignaAssayResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProsignaAssayResultDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaAssayResultValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProsignaAssayResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaResultSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ProsignaResult;

    public constructor(init?: Partial<ProsignaResultSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaResultCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ProsignaResult[];

    public constructor(init?: Partial<ProsignaResultCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaResultDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ProsignaResultDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ProsignaResultValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ProsignaResultValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ShipmentSampleSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: ShipmentSample;

    public constructor(init?: Partial<ShipmentSampleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ShipmentSampleCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: ShipmentSample[];

    public constructor(init?: Partial<ShipmentSampleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ShipmentSampleDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<ShipmentSampleDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class ShipmentSampleValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<ShipmentSampleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TissueSample;

    public constructor(init?: Partial<TissueSampleSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TissueSample[];

    public constructor(init?: Partial<TissueSampleCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TissueSampleDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TissueSampleValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleShipmentSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TissueSampleShipment;

    public constructor(init?: Partial<TissueSampleShipmentSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleShipmentCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TissueSampleShipment[];

    public constructor(init?: Partial<TissueSampleShipmentCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleShipmentDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TissueSampleShipmentDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleShipmentValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TissueSampleShipmentValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleSubmissionSingleResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public form: TissueSampleSubmission;

    public constructor(init?: Partial<TissueSampleSubmissionSingleResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleSubmissionCollectionResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public forms: TissueSampleSubmission[];

    public constructor(init?: Partial<TissueSampleSubmissionCollectionResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleSubmissionDeleteResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    public constructor(init?: Partial<TissueSampleSubmissionDeleteResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class TissueSampleSubmissionValidationResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public validationResult: FormValidationResult;

    public constructor(init?: Partial<TissueSampleSubmissionValidationResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class EventStateResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public states: EventState[];

    public constructor(init?: Partial<EventStateResponse>) { (Object as any).assign(this, init); }
}

// @DataContract
export class FormStateResponse
{
    // @DataMember(Order=1)
    public responseStatus: ResponseStatus;

    // @DataMember(Order=2)
    public states: FormState[];

    public constructor(init?: Partial<FormStateResponse>) { (Object as any).assign(this, init); }
}

export class HasOpmsPermissionCollectionResponse
{
    public responseStatus: ResponseStatus;
    public hasPermission: { [index: number]: boolean; };

    public constructor(init?: Partial<HasOpmsPermissionCollectionResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelResponse
{
    public responseStatus: ResponseStatus;
    public personnel: { [index: number]: InstitutionPersonnel; };

    public constructor(init?: Partial<InstitutionPersonnelResponse>) { (Object as any).assign(this, init); }
}

export class InstitutionPersonnelNamesResponse
{
    public responseStatus: ResponseStatus;
    public personnelNames: { [index: number]: string; };

    public constructor(init?: Partial<InstitutionPersonnelNamesResponse>) { (Object as any).assign(this, init); }
}

export class TrialPersonnelActiveRoleResponse
{
    public responseStatus: ResponseStatus;
    public hasRole: { [index: number]: boolean; };

    public constructor(init?: Partial<TrialPersonnelActiveRoleResponse>) { (Object as any).assign(this, init); }
}

export class SendTissueSampleFormCompletionRequestEmail implements IReturn<SendTissueSampleFormCompletionRequestEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendTissueSampleFormCompletionRequestEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendTissueSampleFormCompletionRequestEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendTissueSampleFormCompletionRequestEmailResponse(); }
}

export class SendPatientWelcomeEmail implements IReturn<SendPatientWelcomeEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientWelcomeEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientWelcomeEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientWelcomeEmailResponse(); }
}

export class SendPatientReminder implements IReturn<SendPatientReminderResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendPatientReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReminderResponse(); }
}

export class SendPatientReminderEmail implements IReturn<SendPatientReminderEmailResponse>
{
    public patientId?: number;
    public resetPassword?: boolean;

    public constructor(init?: Partial<SendPatientReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientReminderEmailResponse(); }
}

export class GetEnumerationTypes implements IReturn<GetEnumerationTypesResponse>
{

    public constructor(init?: Partial<GetEnumerationTypes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEnumerationTypes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetEnumerationTypesResponse(); }
}

export class PatientAccountCreate implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public email: string;
    public password: string;

    public constructor(init?: Partial<PatientAccountCreate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountCreate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountResendWelcome implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<PatientAccountResendWelcome>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountResendWelcome'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountSetPassword implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public password: string;

    public constructor(init?: Partial<PatientAccountSetPassword>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountSetPassword'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class PatientAccountUpdateEmail implements IReturn<ActiveDirectoryResponse>
{
    public patientId?: number;
    public email: string;

    public constructor(init?: Partial<PatientAccountUpdateEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientAccountUpdateEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ActiveDirectoryResponse(); }
}

export class SendPatientNotFoundEmail implements IReturn<SendPatientNotFoundEmailResponse>
{
    public instCode: string;
    public studyNumber: string;
    public initials: string;
    public randDate?: string;

    public constructor(init?: Partial<SendPatientNotFoundEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPatientNotFoundEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPatientNotFoundEmailResponse(); }
}

export class GetConsentHistoryListById implements IReturn<GetConsentHistoryListByIdResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<GetConsentHistoryListById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetConsentHistoryListById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetConsentHistoryListByIdResponse(); }
}

export class ReviewConsent implements IReturn<ReviewConsentResponse>
{
    public consentFormId?: number;
    public consentFormVersion: string;
    public mainConsentDate?: string;
    public mainConsentCurrentTMDVersion?: number;
    public optionalConsentVersion: string;
    public optionalConsentDate?: string;
    public optionalConsentCurrentTMDVersion?: number;
    public optionalConsentSecondVersion: string;
    public optionalConsentSecondDate?: string;
    public optionalConsentCurrentSecondTMDVersion?: number;
    public servicesAustraliaConsentVersion: string;
    public servicesAustraliaConsentDate?: string;
    public servicesAustraliaConsentCurrentTMDVersion?: number;
    public currentPicfsApproved?: number;
    public patientSignedPicfs?: number;
    public allPagesPresent?: number;
    public investigatorSigned?: number;
    public saConsentFullyChecked?: number;
    public personWhoSignedAuth?: number;
    public nameOfPersonWhoSigned: string;
    public ifNoToAnyAboveComment: string;
    public comments: string;
    public reviewedBy: string;
    public reviewedDate?: string;
    public consentStatus?: ConsentStatusType;
    public deleteMainConsent?: number;
    public deleteOptionalConsent?: number;
    public deleteOptionalSecondConsent?: number;
    public deleteSAConsent?: number;
    public participantUsedEConsent?: boolean;
    public saveButtonClicked?: boolean;

    public constructor(init?: Partial<ReviewConsent>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ReviewConsent'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ReviewConsentResponse(); }
}

export class ConfirmProsignaResult implements IReturn<ConfirmProsignaResultResponse>
{
    public prosignaResultId?: number;

    public constructor(init?: Partial<ConfirmProsignaResult>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConfirmProsignaResult'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConfirmProsignaResultResponse(); }
}

export class SendProsignaAssayResultEmail implements IReturn<SendProsignaAssayResultEmailResponse>
{
    public prosignaResultId?: number;

    public constructor(init?: Partial<SendProsignaAssayResultEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendProsignaAssayResultEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendProsignaAssayResultEmailResponse(); }
}

export class SendNoMoreTumourSampleAvailableEmail implements IReturn<SendNoMoreTumourSampleAvailableEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendNoMoreTumourSampleAvailableEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendNoMoreTumourSampleAvailableEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendNoMoreTumourSampleAvailableEmailResponse(); }
}

export class GetProsignaAssayResultStatus implements IReturn<GetProsignaAssayResultStatusResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<GetProsignaAssayResultStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProsignaAssayResultStatus'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetProsignaAssayResultStatusResponse(); }
}

export class SetProsignaAssayResultStatus implements IReturn<SetProsignaAssayResultStatusResponse>
{
    public patientId?: number;
    public status?: number;

    public constructor(init?: Partial<SetProsignaAssayResultStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SetProsignaAssayResultStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SetProsignaAssayResultStatusResponse(); }
}

export class SendInsufficientTumourSampleEmail implements IReturn<SendInsufficientTumourSampleEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendInsufficientTumourSampleEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendInsufficientTumourSampleEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendInsufficientTumourSampleEmailResponse(); }
}

// @Route("/opms/econsent/settings/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetEConsentSettings implements IReturn<GetEConsentSettingsResponse>
{

    public constructor(init?: Partial<GetEConsentSettings>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetEConsentSettings'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetEConsentSettingsResponse(); }
}

export class SendEConsentInvitationEmail implements IReturn<SendEConsentInvitationEmailResponse>
{
    public invitationId: number;

    public constructor(init?: Partial<SendEConsentInvitationEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvitationEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvitationEmailResponse(); }
}

export class SendEConsentInvitationEmailCopy implements IReturn<SendEConsentInvitationEmailCopyResponse>
{
    public invitationId: number;

    public constructor(init?: Partial<SendEConsentInvitationEmailCopy>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvitationEmailCopy'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvitationEmailCopyResponse(); }
}

export class SendEConsentEoiEmail implements IReturn<SendEConsentEoiEmailResponse>
{
    public eoiId: number;

    public constructor(init?: Partial<SendEConsentEoiEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentEoiEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentEoiEmailResponse(); }
}

export class SendEConsentPatientEmail implements IReturn<SendEConsentPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentPatientEmailResponse(); }
}

export class SendEConsentInvestigatorEmail implements IReturn<SendEConsentInvestigatorEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentInvestigatorEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentInvestigatorEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentInvestigatorEmailResponse(); }
}

export class SendEConsentSuccessEmail implements IReturn<SendEConsentSuccessEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessEmailResponse(); }
}

export class SendEConsentSuccessTrialEmail implements IReturn<SendEConsentSuccessTrialEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentSuccessTrialEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentSuccessTrialEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentSuccessTrialEmailResponse(); }
}

export class SendEConsentRefusedEmail implements IReturn<SendEConsentRefusedEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedEmailResponse(); }
}

export class SendEConsentRefusedPatientEmail implements IReturn<SendEConsentRefusedPatientEmailResponse>
{
    public patientConsentId: number;

    public constructor(init?: Partial<SendEConsentRefusedPatientEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendEConsentRefusedPatientEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendEConsentRefusedPatientEmailResponse(); }
}

export class SendScreeningLogReminderEmail implements IReturn<SendScreeningLogReminderEmailResponse>
{
    public code: string;

    public constructor(init?: Partial<SendScreeningLogReminderEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendScreeningLogReminderEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendScreeningLogReminderEmailResponse(); }
}

// @Route("/print/screeninglogs/{InstitutionCode}/{DownloadFormat}/", "GET")
export class GetScreeningLogs
{
    public institutionCode: string;
    public downloadFormat: string;

    public constructor(init?: Partial<GetScreeningLogs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetScreeningLogs'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class SendMissingSourceDocumentEmail implements IReturn<SendMissingSourceDocumentEmailResponse>, IPost
{

    public constructor(init?: Partial<SendMissingSourceDocumentEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendMissingSourceDocumentEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendMissingSourceDocumentEmailResponse(); }
}

export class SendSiteRequestUploadNewConsentEmail implements IReturn<SendSiteRequestUploadNewConsentEmailResponse>
{
    public patientId?: number;
    public deleteMainConsent?: boolean;
    public deleteOptionalConsent?: boolean;
    public deleteSAConsent?: boolean;
    public comments: string;

    public constructor(init?: Partial<SendSiteRequestUploadNewConsentEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendSiteRequestUploadNewConsentEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendSiteRequestUploadNewConsentEmailResponse(); }
}

export class SendBCTNewConsentUploadEmail implements IReturn<SendBCTNewConsentUploadEmailResponse>
{
    public patientId?: number;

    public constructor(init?: Partial<SendBCTNewConsentUploadEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendBCTNewConsentUploadEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendBCTNewConsentUploadEmailResponse(); }
}

export class SendProsignaAssayEmail implements IReturn<SendProsignaAssayEmailResponse>, IPost
{

    public constructor(init?: Partial<SendProsignaAssayEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendProsignaAssayEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendProsignaAssayEmailResponse(); }
}

export class SendOverdueProcomidaEmail implements IReturn<SendOverdueProcomidaEmailResponse>, IPost
{
    public institutionId?: number;

    public constructor(init?: Partial<SendOverdueProcomidaEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendOverdueProcomidaEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendOverdueProcomidaEmailResponse(); }
}

export class GenerateOverdueProcomidaEmail implements IReturn<GenerateOverdueProcomidaEmailResponse>
{

    public constructor(init?: Partial<GenerateOverdueProcomidaEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GenerateOverdueProcomidaEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GenerateOverdueProcomidaEmailResponse(); }
}

export class ProcessEmail implements IReturn<ProcessEmailResponse>
{
    public id: string;

    public constructor(init?: Partial<ProcessEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcessEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcessEmailResponse(); }
}

export class QueueNewEmailsToParse implements IReturn<QueueNewEmailsToParseResponse>
{

    public constructor(init?: Partial<QueueNewEmailsToParse>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueNewEmailsToParse'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueNewEmailsToParseResponse(); }
}

export class QueueReprocessEmailsToParse implements IReturn<QueueReprocessEmailsToParseResponse>
{

    public constructor(init?: Partial<QueueReprocessEmailsToParse>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'QueueReprocessEmailsToParse'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new QueueReprocessEmailsToParseResponse(); }
}

export class GetOverdueProcomida implements IReturn<GetOverdueProcomidaResponse>, IGet
{
    public institutionId: number;

    public constructor(init?: Partial<GetOverdueProcomida>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetOverdueProcomida'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetOverdueProcomidaResponse(); }
}

export class GetProsignaAssayEmailRow implements IReturn<GetProsignaAssayEmailRowsResponse>, IGet
{

    public constructor(init?: Partial<GetProsignaAssayEmailRow>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProsignaAssayEmailRow'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetProsignaAssayEmailRowsResponse(); }
}

// @Route("/report/prosigna-results", "GET,POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetProsignaResultsReportData implements IReturn<GetProsignaResultsReportDataResponse>, IReportRequest
{
    public token: string;

    public constructor(init?: Partial<GetProsignaResultsReportData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetProsignaResultsReportData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new GetProsignaResultsReportDataResponse(); }
}

export class SendPathologyRequisitionFormEmail implements IReturn<SendPathologyRequisitionFormEmailResponse>
{
    public patientId?: number;
    public tissueSampleId?: number;

    public constructor(init?: Partial<SendPathologyRequisitionFormEmail>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SendPathologyRequisitionFormEmail'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SendPathologyRequisitionFormEmailResponse(); }
}

// @Route("/pathology-requisition-form")
export class GetPathologyRequisitionForm
{
    public patientId?: number;
    public tissueSampleId?: number;

    public constructor(init?: Partial<GetPathologyRequisitionForm>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPathologyRequisitionForm'; }
    public getMethod() { return 'GET'; }
    public createResponse() {}
}

export class ExportPatientData implements IReturn<ExportPatientDataResponse>
{

    public constructor(init?: Partial<ExportPatientData>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ExportPatientData'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ExportPatientDataResponse(); }
}

export class GetMissingSourceDocuments implements IReturn<GetMissingSourceDocumentsResponse>, IGet
{

    public constructor(init?: Partial<GetMissingSourceDocuments>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetMissingSourceDocuments'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetMissingSourceDocumentsResponse(); }
}

// @Route("/get/tp-registration/", "GET")
// @Route("/get/tp-registration/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpRegistrationByInstitutionId implements IReturn<GetTpRegistrationByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpRegistrationByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpRegistrationByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpRegistrationByInstitutionIdResponse(); }
}

// @Route("/get/tp-screening/", "GET")
// @Route("/get/tp-screening/{InstitutionId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetTpScreeningByInstitutionId implements IReturn<GetTpScreeningByInstitutionIdResponse>
{
    public institutionId?: number;

    public constructor(init?: Partial<GetTpScreeningByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTpScreeningByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetTpScreeningByInstitutionIdResponse(); }
}

/** @description Sign In */
// @Route("/auth", "OPTIONS,GET,POST,DELETE")
// @Route("/auth/{provider}", "OPTIONS,GET,POST,DELETE")
// @Api(Description="Sign In")
// @DataContract
export class Authenticate implements IReturn<AuthenticateResponse>, IPost
{
    /** @description AuthProvider, e.g. credentials */
    // @DataMember(Order=1)
    public provider: string;

    // @DataMember(Order=2)
    public state: string;

    // @DataMember(Order=3)
    public oauth_token: string;

    // @DataMember(Order=4)
    public oauth_verifier: string;

    // @DataMember(Order=5)
    public userName: string;

    // @DataMember(Order=6)
    public password: string;

    // @DataMember(Order=7)
    public rememberMe?: boolean;

    // @DataMember(Order=9)
    public errorView: string;

    // @DataMember(Order=10)
    public nonce: string;

    // @DataMember(Order=11)
    public uri: string;

    // @DataMember(Order=12)
    public response: string;

    // @DataMember(Order=13)
    public qop: string;

    // @DataMember(Order=14)
    public nc: string;

    // @DataMember(Order=15)
    public cnonce: string;

    // @DataMember(Order=17)
    public accessToken: string;

    // @DataMember(Order=18)
    public accessTokenSecret: string;

    // @DataMember(Order=19)
    public scope: string;

    // @DataMember(Order=20)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<Authenticate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Authenticate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AuthenticateResponse(); }
}

// @Route("/assignroles", "POST")
// @DataContract
export class AssignRoles implements IReturn<AssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<AssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AssignRolesResponse(); }
}

// @Route("/unassignroles", "POST")
// @DataContract
export class UnAssignRoles implements IReturn<UnAssignRolesResponse>, IPost
{
    // @DataMember(Order=1)
    public userName: string;

    // @DataMember(Order=2)
    public permissions: string[];

    // @DataMember(Order=3)
    public roles: string[];

    // @DataMember(Order=4)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<UnAssignRoles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'UnAssignRoles'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UnAssignRolesResponse(); }
}

// @Route("/apikeys")
// @Route("/apikeys/{Environment}")
// @DataContract
export class GetApiKeys implements IReturn<GetApiKeysResponse>, IGet
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<GetApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetApiKeys'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetApiKeysResponse(); }
}

// @Route("/apikeys/regenerate")
// @Route("/apikeys/regenerate/{Environment}")
// @DataContract
export class RegenerateApiKeys implements IReturn<RegenerateApiKeysResponse>, IPost
{
    // @DataMember(Order=1)
    public environment: string;

    // @DataMember(Order=2)
    public meta: { [index: string]: string; };

    public constructor(init?: Partial<RegenerateApiKeys>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'RegenerateApiKeys'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new RegenerateApiKeysResponse(); }
}

// @Route("/opms/screeninglog/single/id/{Id}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleById implements IReturn<ScreeningLogSingleResponse>, IScreeningLogGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

// @Route("/opms/screeninglog/collection", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollection implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollection
{

    public constructor(init?: Partial<ScreeningLogGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

// @Route("/opms/screeninglog/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogCollectionResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetCollectionByInstitutionId implements IReturn<ScreeningLogCollectionResponse>, IScreeningLogGetCollectionByInstitutionId
{
    // @DataMember(Order=1)
    public institutionId?: number;

    public constructor(init?: Partial<ScreeningLogGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogCollectionResponse(); }
}

// @Route("/opms/screeninglog/save", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostSave implements IReturn<ScreeningLogSingleResponse>
{
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogSingleResponse(); }
}

// @Route("/opms/screeninglog/delete/{ScreeningLogId}", "DELETE")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogDeleteResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogDelete implements IReturn<ScreeningLogDeleteResponse>, IScreeningLogDelete
{
    // @DataMember(Order=1)
    public screeningLogId: number;

    public constructor(init?: Partial<ScreeningLogDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ScreeningLogDeleteResponse(); }
}

// @Route("/opms/screeninglog/validate", "POST")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(NewtonGreen.ServiceStack.Opms.ScreeningLog.Models.Utility.ScreeningLogFormValidationResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogPostValidate implements IReturn<ScreeningLogFormValidationResponse>
{
    // @DataMember(Order=1)
    public screeningLog: ScreeningLog;

    public constructor(init?: Partial<ScreeningLogPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ScreeningLogFormValidationResponse(); }
}

// @Route("/opms/screeninglog/single/patient-id/{PatientId}", "GET")
// @Api(Description="ScreeningLog")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__ScreeningLog.ScreeningLog.ScreeningLogSingleByPatientIdResponse)", StatusCode=500)
// @DataContract
export class ScreeningLogGetSingleByPatientId implements IReturn<ScreeningLogSingleByPatientIdResponse>, IScreeningLogGetSingleByPatientId
{
    // @DataMember(Order=1)
    public patientId?: number;

    public constructor(init?: Partial<ScreeningLogGetSingleByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ScreeningLogGetSingleByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ScreeningLogSingleByPatientIdResponse(); }
}

// @Route("/opms/invitation/collection", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetCollection implements IReturn<InvitationGetCollectionResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetCollectionResponse(); }
}

// @Route("/opms/invitation/single/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleById implements IReturn<InvitationGetSingleByIdResponse>, IGet
{
    public id?: number;

    public constructor(init?: Partial<InvitationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByIdResponse(); }
}

// @Route("/opms/invitation/single/{Guid}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetSingleByGuid implements IReturn<InvitationGetSingleByGuidResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<InvitationGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetSingleByGuidResponse(); }
}

// @Route("/opms/invitation/save", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationSave implements IReturn<InvitationSaveResponse>, IPost
{
    public invitation: Invitation;

    public constructor(init?: Partial<InvitationSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new InvitationSaveResponse(); }
}

// @Route("/opms/invitation/delete/{Id}", "DELETE")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationDelete implements IReturn<InvitationDeleteResponse>, IDelete
{
    public id?: number;

    public constructor(init?: Partial<InvitationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new InvitationDeleteResponse(); }
}

// @Route("/opms/invitation/investigators", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class InvitationGetInvestigators implements IReturn<InvitationGetInvestigatorsResponse>, IGet
{
    public institutionCode: string;

    public constructor(init?: Partial<InvitationGetInvestigators>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InvitationGetInvestigators'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InvitationGetInvestigatorsResponse(); }
}

// @Route("/opms/picf/{InstCode}/{PatientConsentId}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetPicfs implements IReturn<GetPicfsResponse>, IGet
{
    public instCode: string;
    public patientConsentId?: number;

    public constructor(init?: Partial<GetPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetPicfsResponse(); }
}

// @Route("/opms/picf/download/all/{InstCode}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicfs implements IReturn<Blob>, IGet
{
    public instCode: string;

    public constructor(init?: Partial<GetDownloadPicfs>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicfs'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/picf/download/{InstCode}/{PicfId}/{PicfTypeId}/", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class GetDownloadPicf implements IReturn<Blob>, IGet
{
    public instCode: string;
    public picfId?: number;
    public picfTypeId?: number;

    public constructor(init?: Partial<GetDownloadPicf>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetDownloadPicf'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/eoi/single/id/{Id}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiGetSingleById implements IReturn<EoiSingleResponse>, IEoiGetSingleById
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiSingleResponse(); }
}

// @Route("/opms/eoi/collection", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollection implements IReturn<EoiCollectionResponse>, IEoiGetCollection
{

    public constructor(init?: Partial<EoiGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

// @Route("/opms/eoi/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiCollectionResponse)", StatusCode=500)
// @DataContract
export class EoiGetCollectionByInstitutionCode implements IReturn<EoiCollectionResponse>, IEoiGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<EoiGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EoiCollectionResponse(); }
}

// @Route("/opms/eoi/save", "POST")
// @Api(Description="Eoi")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiSingleResponse)", StatusCode=500)
// @DataContract
export class EoiPostSave implements IReturn<EoiSingleResponse>
{
    // @DataMember(Order=1)
    public eoi: Eoi;

    // @DataMember(Order=2)
    public invitationGuid: string;

    public constructor(init?: Partial<EoiPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EoiSingleResponse(); }
}

// @Route("/opms/eoi/delete/{Id}", "DELETE")
// @Api(Description="Eoi")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.Eoi.EoiDeleteResponse)", StatusCode=500)
// @DataContract
export class EoiDelete implements IReturn<EoiDeleteResponse>, IEoiDelete
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<EoiDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new EoiDeleteResponse(); }
}

// @Route("/opms/eoi/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class EoiSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<EoiSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EoiSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patientconsent/single/id/{Id}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleById implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleById
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/single/guid/{Guid}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetSingleByGuid implements IReturn<PatientConsentSingleResponse>, IPatientConsentGetSingleByGuid
{
    // @DataMember(Order=1)
    public guid: string;

    public constructor(init?: Partial<PatientConsentGetSingleByGuid>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetSingleByGuid'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/collection", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollection implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollection
{

    public constructor(init?: Partial<PatientConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

// @Route("/opms/patientconsent/collection/institution/{InstitutionCode}", "GET")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientConsentGetCollectionByInstitutionCode implements IReturn<PatientConsentCollectionResponse>, IPatientConsentGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    public constructor(init?: Partial<PatientConsentGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientConsentCollectionResponse(); }
}

// @Route("/opms/patientconsent/save", "POST")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentSingleResponse)", StatusCode=500)
// @DataContract
export class PatientConsentPostSave implements IReturn<PatientConsentSingleResponse>
{
    // @DataMember(Order=1)
    public patientConsent: PatientConsent;

    // @DataMember(Order=1)
    public emailRequired: boolean;

    public constructor(init?: Partial<PatientConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSingleResponse(); }
}

// @Route("/opms/patientconsent/delete/{Id}", "DELETE")
// @Api(Description="PatientConsent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__EConsent.PatientConsent.PatientConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class PatientConsentDelete implements IReturn<PatientConsentDeleteResponse>, IPatientConsentDelete
{
    // @DataMember(Order=1)
    public id: number;

    public constructor(init?: Partial<PatientConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new PatientConsentDeleteResponse(); }
}

// @Route("/opms/patient-consent/save-status", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSaveStatus implements IReturn<ResponseStatus>, IPost
{
    public id?: number;
    public statusId?: StatusTypeEnum;

    public constructor(init?: Partial<PatientConsentSaveStatus>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSaveStatus'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-reminder", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendReminder implements IReturn<ResponseStatus>, IPost
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentSendReminder>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendReminder'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ResponseStatus(); }
}

// @Route("/opms/patient-consent/send-verification-code", "POST")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentSendVerificationCode implements IReturn<PatientConsentSendVerificationCodeResponse>, IPost
{
    public phoneNumber: string;

    public constructor(init?: Partial<PatientConsentSendVerificationCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentSendVerificationCode'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientConsentSendVerificationCodeResponse(); }
}

// @Route("/opms/patient-consent/view-consent-file/{Id}", "GET")
// @ApiResponse(Description="Unauthorized.", StatusCode=401)
// @ApiResponse(Description="Forbidden.", StatusCode=403)
// @ApiResponse(Description="Bad Request.", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", StatusCode=500)
export class PatientConsentViewConsentFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<PatientConsentViewConsentFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientConsentViewConsentFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload", "POST")
export class PostUploadFile implements IReturn<UploadResponse>, IPost
{

    public constructor(init?: Partial<PostUploadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostUploadFile'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/info/{Guid}", "GET")
export class GetUploadInfo implements IReturn<UploadResponse>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUploadInfo>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUploadInfo'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/file-upload/{Guid}", "GET")
export class GetUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<GetUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/download/{Guid}", "GET")
export class DownloadUpload implements IReturn<Blob>, IGet
{
    public guid: string;

    public constructor(init?: Partial<DownloadUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadUpload'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file-upload/{Guid}", "DELETE")
export class DeleteUpload implements IReturn<UploadResponse>, IDelete
{
    public guid: string;

    public constructor(init?: Partial<DeleteUpload>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DeleteUpload'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new UploadResponse(); }
}

// @Route("/opms/user")
export class GetUser implements IReturn<GetUserResponse>, IGet
{

    public constructor(init?: Partial<GetUser>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetUser'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetUserResponse(); }
}

// @Route("/opms/lookup/collection/form-definition/{FormDefinitionId}", "GET")
// @Api(Description="Lookup")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.LookupCollectionResponse)", StatusCode=500)
// @DataContract
export class LookupGetCollectionByFormDefinitionId implements IReturn<LookupCollectionResponse>, ILookupGetCollectionByFormDefinitionId
{
    // @DataMember(Order=1)
    public formDefinitionId?: number;

    public constructor(init?: Partial<LookupGetCollectionByFormDefinitionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'LookupGetCollectionByFormDefinitionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new LookupCollectionResponse(); }
}

// @Route("/opms/form-names")
export class GetFormMetadata implements IReturn<GetFormMetadataResponse>
{

    public constructor(init?: Partial<GetFormMetadata>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFormMetadata'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new GetFormMetadataResponse(); }
}

// @Route("/opms/event-definition/single/id/{Id}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleById implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

// @Route("/opms/event-definition/single/code/{Code}", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetSingleByCode implements IReturn<EventDefinitionSingleResponse>, IEventDefinitionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<EventDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionSingleResponse(); }
}

// @Route("/opms/event-definition/collection", "GET")
// @Api(Description="Event Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventDefinition.EventDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class EventDefinitionGetCollection implements IReturn<EventDefinitionCollectionResponse>, IEventDefinitionGetCollection
{

    public constructor(init?: Partial<EventDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventDefinitionCollectionResponse(); }
}

// @Route("/opms/form-definition/single/id/{Id}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleById implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FormDefinitionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/opms/form-definition/single/code/{Code}", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionSingleResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetSingleByCode implements IReturn<FormDefinitionSingleResponse>, IFormDefinitionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<FormDefinitionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionSingleResponse(); }
}

// @Route("/opms/form-definition/collection", "GET")
// @Api(Description="Form Definition")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.FormDefinition.FormDefinitionCollectionResponse)", StatusCode=500)
// @DataContract
export class FormDefinitionGetCollection implements IReturn<FormDefinitionCollectionResponse>, IFormDefinitionGetCollection
{

    public constructor(init?: Partial<FormDefinitionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormDefinitionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormDefinitionCollectionResponse(); }
}

// @Route("/opms/patient-state/single/id/{Id}", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateSingleResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetSingleById implements IReturn<PatientStateSingleResponse>, IPatientStateGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientStateGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateSingleResponse(); }
}

// @Route("/opms/patient-state/collection", "GET")
// @Api(Description="Patient State")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.PatientState.PatientStateCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientStateGetCollection implements IReturn<PatientStateCollectionResponse>, IPatientStateGetCollection
{

    public constructor(init?: Partial<PatientStateGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientStateGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientStateCollectionResponse(); }
}

// @Route("/opms/treatment/single/id/{Id}", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentSingleResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetSingleById implements IReturn<TreatmentSingleResponse>, ITreatmentGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TreatmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentSingleResponse(); }
}

// @Route("/opms/treatment/collection", "GET")
// @Api(Description="Treatment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Treatment.TreatmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TreatmentGetCollection implements IReturn<TreatmentCollectionResponse>, ITreatmentGetCollection
{

    public constructor(init?: Partial<TreatmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TreatmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TreatmentCollectionResponse(); }
}

// @Route("/opms/master-group/single/id/{Id}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleById implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MasterGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/opms/master-group/single/code/{Code}", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupSingleResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetSingleByCode implements IReturn<MasterGroupSingleResponse>, IMasterGroupGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<MasterGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupSingleResponse(); }
}

// @Route("/opms/master-group/collection", "GET")
// @Api(Description="Master Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.MasterGroup.MasterGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class MasterGroupGetCollection implements IReturn<MasterGroupCollectionResponse>, IMasterGroupGetCollection
{

    public constructor(init?: Partial<MasterGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MasterGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MasterGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/single/id/{Id}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleById implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CollaboratingGroupGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

// @Route("/opms/collaborating-group/single/code/{Code}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupSingleResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetSingleByCode implements IReturn<CollaboratingGroupSingleResponse>, ICollaboratingGroupGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CollaboratingGroupGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupSingleResponse(); }
}

// @Route("/opms/collaborating-group/collection", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollection implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollection
{

    public constructor(init?: Partial<CollaboratingGroupGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupId implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/collaborating-group/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Collaborating Group")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.CollaboratingGroup.CollaboratingGroupCollectionResponse)", StatusCode=500)
// @DataContract
export class CollaboratingGroupGetCollectionByMasterGroupCode implements IReturn<CollaboratingGroupCollectionResponse>, ICollaboratingGroupGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CollaboratingGroupGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CollaboratingGroupGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CollaboratingGroupCollectionResponse(); }
}

// @Route("/opms/country/single/id/{Id}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleById implements IReturn<CountrySingleResponse>, ICountryGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CountryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/opms/country/single/code/{Code}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountrySingleResponse)", StatusCode=500)
// @DataContract
export class CountryGetSingleByCode implements IReturn<CountrySingleResponse>, ICountryGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<CountryGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountrySingleResponse(); }
}

// @Route("/opms/country/collection", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollection implements IReturn<CountryCollectionResponse>, ICountryGetCollection
{

    public constructor(init?: Partial<CountryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByMasterGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupId implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId?: number;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/country/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Country")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Country.CountryCollectionResponse)", StatusCode=500)
// @DataContract
export class CountryGetCollectionByCollaboratingGroupCode implements IReturn<CountryCollectionResponse>, ICountryGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    public constructor(init?: Partial<CountryGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CountryGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CountryCollectionResponse(); }
}

// @Route("/opms/institution/single/id/{Id}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleById implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<InstitutionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/opms/institution/single/code/{Code}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionSingleResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetSingleByCode implements IReturn<InstitutionSingleResponse>, IInstitutionGetSingleByCode
{
    // @DataMember(Order=1)
    public code: string;

    public constructor(init?: Partial<InstitutionGetSingleByCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetSingleByCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionSingleResponse(); }
}

// @Route("/opms/institution/collection", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollection implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollection
{

    public constructor(init?: Partial<InstitutionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/country/id/{CountryId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryId
{
    // @DataMember(Order=1)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupId implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCountryCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCountryCode
{
    // @DataMember(Order=1)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByMasterGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/institution/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Institution")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Institution.InstitutionCollectionResponse)", StatusCode=500)
// @DataContract
export class InstitutionGetCollectionByCollaboratingGroupCode implements IReturn<InstitutionCollectionResponse>, IInstitutionGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    public constructor(init?: Partial<InstitutionGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'InstitutionGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionCollectionResponse(); }
}

// @Route("/opms/patient/single/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleById implements IReturn<PatientSingleResponse>, IPatientGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/single/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientGetSingleByStudyNumber implements IReturn<PatientSingleResponse>, IPatientGetSingleByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetSingleByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetSingleByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/validation/id/{Id}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationById implements IReturn<PatientValidationResponse>, IPatientGetValidationById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<PatientGetValidationById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/opms/patient/validation/study-number/{StudyNumber}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientValidationResponse)", StatusCode=500)
// @DataContract
export class PatientGetValidationByStudyNumber implements IReturn<PatientValidationResponse>, IPatientGetValidationByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<PatientGetValidationByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetValidationByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientValidationResponse(); }
}

// @Route("/opms/patient/collection", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollection implements IReturn<PatientCollectionResponse>, IPatientGetCollection
{
    // @DataMember(Order=1)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/master-group/id/{MasterGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupId
{
    // @DataMember(Order=1)
    public masterGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/collaborating-group/id/{CollaboratingGroupId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupId
{
    // @DataMember(Order=1)
    public collaboratingGroupId: number;

    // @DataMember(Order=2)
    public countryId?: number;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/country/id/{CountryId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryId
{
    // @DataMember(Order=1)
    public countryId: number;

    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/institution/id/{InstitutionId}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionId implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionId
{
    // @DataMember(Order=1)
    public institutionId: number;

    // @DataMember(Order=2)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/master-group/code/{MasterGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByMasterGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByMasterGroupCode
{
    // @DataMember(Order=1)
    public masterGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByMasterGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByMasterGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/collaborating-group/code/{CollaboratingGroupCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCollaboratingGroupCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCollaboratingGroupCode
{
    // @DataMember(Order=1)
    public collaboratingGroupCode: string;

    // @DataMember(Order=2)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCollaboratingGroupCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCollaboratingGroupCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/country/code/{CountryCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByCountryCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByCountryCode
{
    // @DataMember(Order=1)
    public countryCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByCountryCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByCountryCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/collection/institution/code/{InstitutionCode}", "GET")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientCollectionResponse)", StatusCode=500)
// @DataContract
export class PatientGetCollectionByInstitutionCode implements IReturn<PatientCollectionResponse>, IPatientGetCollectionByInstitutionCode
{
    // @DataMember(Order=1)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStateIds: number[];

    public constructor(init?: Partial<PatientGetCollectionByInstitutionCode>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientGetCollectionByInstitutionCode'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new PatientCollectionResponse(); }
}

// @Route("/opms/patient/Save", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSave implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    public constructor(init?: Partial<PatientPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/save/ids", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithIds implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    // @DataMember(Order=2)
    public institutionId: number;

    public constructor(init?: Partial<PatientPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/patient/save/codes", "POST")
// @Api(Description="Patient")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Patient.PatientSingleResponse)", StatusCode=500)
// @DataContract
export class PatientPostSaveWithCodes implements IReturn<PatientSingleResponse>
{
    // @DataMember(Order=1)
    public patient: Patient;

    // @DataMember(Order=2)
    public institutionCode: string;

    public constructor(init?: Partial<PatientPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PatientPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new PatientSingleResponse(); }
}

// @Route("/opms/event/single/id/{Id}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleById implements IReturn<EventSingleResponse>, IEventGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/single/ids", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByIds implements IReturn<EventSingleResponse>, IEventGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public institutionId?: number;

    // @DataMember(Order=5)
    public createPatient?: boolean;

    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/single/codes", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventGetSingleByCodes implements IReturn<EventSingleResponse>, IEventGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public institutionCode: string;

    // @DataMember(Order=5)
    public createPatient?: boolean;

    // @DataMember(Order=6)
    public createEvent?: boolean;

    public constructor(init?: Partial<EventGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/collection", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollection implements IReturn<EventCollectionResponse>, IEventGetCollection
{

    public constructor(init?: Partial<EventGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/collection/patient/id/{PatientId}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientId implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientId
{
    // @DataMember(Order=1)
    public patientId: number;

    public constructor(init?: Partial<EventGetCollectionByPatientId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientId'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/collection/patient/study-number/{PatientStudyNumber}", "GET")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventCollectionResponse)", StatusCode=500)
// @DataContract
export class EventGetCollectionByPatientStudyNumber implements IReturn<EventCollectionResponse>, IEventGetCollectionByPatientStudyNumber
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    public constructor(init?: Partial<EventGetCollectionByPatientStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventGetCollectionByPatientStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventCollectionResponse(); }
}

// @Route("/opms/event/Save", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSave implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    public constructor(init?: Partial<EventPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/save/ids", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithIds implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/event/save/codes", "POST")
// @Api(Description="Event")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Event.EventSingleResponse)", StatusCode=500)
// @DataContract
export class EventPostSaveWithCodes implements IReturn<EventSingleResponse>
{
    // @DataMember(Order=1)
    public event: Event;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    public constructor(init?: Partial<EventPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new EventSingleResponse(); }
}

// @Route("/opms/form/source-document/single/id/{Id}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleById implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/single/ids", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByIds implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/single/codes", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetSingleByCodes implements IReturn<SourceDocumentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<SourceDocumentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/collection", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollection implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<SourceDocumentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByIds implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentCollectionResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentGetCollectionByCodes implements IReturn<SourceDocumentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new SourceDocumentCollectionResponse(); }
}

// @Route("/opms/form/source-document/save", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSave implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/save/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithIds implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/save/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentSingleResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostSaveWithCodes implements IReturn<SourceDocumentSingleResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<SourceDocumentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentSingleResponse(); }
}

// @Route("/opms/form/source-document/delete", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDelete implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithId implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/ids", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteWithIds implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/delete/codes", "DELETE")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentDeleteResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentDeleteByCodes implements IReturn<SourceDocumentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<SourceDocumentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new SourceDocumentDeleteResponse(); }
}

// @Route("/opms/form/source-document/validate", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidate implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    public constructor(init?: Partial<SourceDocumentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/source-document/validate/ids", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithIds implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/source-document/validate/codes", "POST")
// @Api(Description="Form - Source Document")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.SourceDocumentValidationResponse)", StatusCode=500)
// @DataContract
export class SourceDocumentPostValidateWithCodes implements IReturn<SourceDocumentValidationResponse>
{
    // @DataMember(Order=1)
    public form: SourceDocument;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<SourceDocumentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'SourceDocumentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new SourceDocumentValidationResponse(); }
}

// @Route("/opms/form/additional/single/id/{Id}", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetSingleById implements IReturn<AdditionalSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/single/ids", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetSingleByIds implements IReturn<AdditionalSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/single/codes", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetSingleByCodes implements IReturn<AdditionalSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<AdditionalGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/collection", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetCollection implements IReturn<AdditionalCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<AdditionalGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalCollectionResponse(); }
}

// @Route("/opms/form/additional/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetCollectionByIds implements IReturn<AdditionalCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalCollectionResponse(); }
}

// @Route("/opms/form/additional/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalCollectionResponse)", StatusCode=500)
// @DataContract
export class AdditionalGetCollectionByCodes implements IReturn<AdditionalCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new AdditionalCollectionResponse(); }
}

// @Route("/opms/form/additional/save", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostSave implements IReturn<AdditionalSingleResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    public constructor(init?: Partial<AdditionalPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/save/ids", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostSaveWithIds implements IReturn<AdditionalSingleResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/save/codes", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalSingleResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostSaveWithCodes implements IReturn<AdditionalSingleResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<AdditionalPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalSingleResponse(); }
}

// @Route("/opms/form/additional/delete", "DELETE")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalDelete implements IReturn<AdditionalDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    public constructor(init?: Partial<AdditionalDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalDeleteResponse(); }
}

// @Route("/opms/form/additional/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalDeleteWithId implements IReturn<AdditionalDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<AdditionalDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalDeleteResponse(); }
}

// @Route("/opms/form/additional/delete/ids", "DELETE")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalDeleteWithIds implements IReturn<AdditionalDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalDeleteResponse(); }
}

// @Route("/opms/form/additional/delete/codes", "DELETE")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalDeleteResponse)", StatusCode=500)
// @DataContract
export class AdditionalDeleteByCodes implements IReturn<AdditionalDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<AdditionalDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new AdditionalDeleteResponse(); }
}

// @Route("/opms/form/additional/validate", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostValidate implements IReturn<AdditionalValidationResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    public constructor(init?: Partial<AdditionalPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalValidationResponse(); }
}

// @Route("/opms/form/additional/validate/ids", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostValidateWithIds implements IReturn<AdditionalValidationResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalValidationResponse(); }
}

// @Route("/opms/form/additional/validate/codes", "POST")
// @Api(Description="Form - Additional")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.AdditionalValidationResponse)", StatusCode=500)
// @DataContract
export class AdditionalPostValidateWithCodes implements IReturn<AdditionalValidationResponse>
{
    // @DataMember(Order=1)
    public form: Additional;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<AdditionalPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'AdditionalPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new AdditionalValidationResponse(); }
}

// @Route("/opms/form/community-service/single/id/{Id}", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetSingleById implements IReturn<CommunityServiceSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CommunityServiceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/single/ids", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetSingleByIds implements IReturn<CommunityServiceSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CommunityServiceGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/single/codes", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetSingleByCodes implements IReturn<CommunityServiceSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<CommunityServiceGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/collection", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetCollection implements IReturn<CommunityServiceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<CommunityServiceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceCollectionResponse(); }
}

// @Route("/opms/form/community-service/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetCollectionByIds implements IReturn<CommunityServiceCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CommunityServiceGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceCollectionResponse(); }
}

// @Route("/opms/form/community-service/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceGetCollectionByCodes implements IReturn<CommunityServiceCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<CommunityServiceGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new CommunityServiceCollectionResponse(); }
}

// @Route("/opms/form/community-service/save", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostSave implements IReturn<CommunityServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    public constructor(init?: Partial<CommunityServicePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/save/ids", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostSaveWithIds implements IReturn<CommunityServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CommunityServicePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/save/codes", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceSingleResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostSaveWithCodes implements IReturn<CommunityServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<CommunityServicePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceSingleResponse(); }
}

// @Route("/opms/form/community-service/delete", "DELETE")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceDelete implements IReturn<CommunityServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    public constructor(init?: Partial<CommunityServiceDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CommunityServiceDeleteResponse(); }
}

// @Route("/opms/form/community-service/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceDeleteWithId implements IReturn<CommunityServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<CommunityServiceDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CommunityServiceDeleteResponse(); }
}

// @Route("/opms/form/community-service/delete/ids", "DELETE")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceDeleteWithIds implements IReturn<CommunityServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CommunityServiceDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CommunityServiceDeleteResponse(); }
}

// @Route("/opms/form/community-service/delete/codes", "DELETE")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class CommunityServiceDeleteByCodes implements IReturn<CommunityServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<CommunityServiceDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServiceDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new CommunityServiceDeleteResponse(); }
}

// @Route("/opms/form/community-service/validate", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostValidate implements IReturn<CommunityServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    public constructor(init?: Partial<CommunityServicePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceValidationResponse(); }
}

// @Route("/opms/form/community-service/validate/ids", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostValidateWithIds implements IReturn<CommunityServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CommunityServicePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceValidationResponse(); }
}

// @Route("/opms/form/community-service/validate/codes", "POST")
// @Api(Description="Form - Community Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.CommunityServiceValidationResponse)", StatusCode=500)
// @DataContract
export class CommunityServicePostValidateWithCodes implements IReturn<CommunityServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: CommunityService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<CommunityServicePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'CommunityServicePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new CommunityServiceValidationResponse(); }
}

// @Route("/opms/form/distress/single/id/{Id}", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressGetSingleById implements IReturn<DistressSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DistressGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/single/ids", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressGetSingleByIds implements IReturn<DistressSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DistressGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/single/codes", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressGetSingleByCodes implements IReturn<DistressSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<DistressGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/collection", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=500)
// @DataContract
export class DistressGetCollection implements IReturn<DistressCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<DistressGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressCollectionResponse(); }
}

// @Route("/opms/form/distress/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=500)
// @DataContract
export class DistressGetCollectionByIds implements IReturn<DistressCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DistressGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressCollectionResponse(); }
}

// @Route("/opms/form/distress/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressCollectionResponse)", StatusCode=500)
// @DataContract
export class DistressGetCollectionByCodes implements IReturn<DistressCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<DistressGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new DistressCollectionResponse(); }
}

// @Route("/opms/form/distress/save", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressPostSave implements IReturn<DistressSingleResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    public constructor(init?: Partial<DistressPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/save/ids", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressPostSaveWithIds implements IReturn<DistressSingleResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DistressPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/save/codes", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressSingleResponse)", StatusCode=500)
// @DataContract
export class DistressPostSaveWithCodes implements IReturn<DistressSingleResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<DistressPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressSingleResponse(); }
}

// @Route("/opms/form/distress/delete", "DELETE")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=500)
// @DataContract
export class DistressDelete implements IReturn<DistressDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    public constructor(init?: Partial<DistressDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DistressDeleteResponse(); }
}

// @Route("/opms/form/distress/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=500)
// @DataContract
export class DistressDeleteWithId implements IReturn<DistressDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<DistressDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DistressDeleteResponse(); }
}

// @Route("/opms/form/distress/delete/ids", "DELETE")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=500)
// @DataContract
export class DistressDeleteWithIds implements IReturn<DistressDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DistressDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DistressDeleteResponse(); }
}

// @Route("/opms/form/distress/delete/codes", "DELETE")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressDeleteResponse)", StatusCode=500)
// @DataContract
export class DistressDeleteByCodes implements IReturn<DistressDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<DistressDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new DistressDeleteResponse(); }
}

// @Route("/opms/form/distress/validate", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=500)
// @DataContract
export class DistressPostValidate implements IReturn<DistressValidationResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    public constructor(init?: Partial<DistressPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressValidationResponse(); }
}

// @Route("/opms/form/distress/validate/ids", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=500)
// @DataContract
export class DistressPostValidateWithIds implements IReturn<DistressValidationResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DistressPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressValidationResponse(); }
}

// @Route("/opms/form/distress/validate/codes", "POST")
// @Api(Description="Form - Distress")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.DistressValidationResponse)", StatusCode=500)
// @DataContract
export class DistressPostValidateWithCodes implements IReturn<DistressValidationResponse>
{
    // @DataMember(Order=1)
    public form: Distress;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<DistressPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DistressPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new DistressValidationResponse(); }
}

// @Route("/opms/form/eq5d/single/id/{Id}", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetSingleById implements IReturn<Eq5dSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<Eq5dGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/single/ids", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetSingleByIds implements IReturn<Eq5dSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<Eq5dGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/single/codes", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetSingleByCodes implements IReturn<Eq5dSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<Eq5dGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/collection", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetCollection implements IReturn<Eq5dCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<Eq5dGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dCollectionResponse(); }
}

// @Route("/opms/form/eq5d/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetCollectionByIds implements IReturn<Eq5dCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5dGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dCollectionResponse(); }
}

// @Route("/opms/form/eq5d/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dCollectionResponse)", StatusCode=500)
// @DataContract
export class Eq5dGetCollectionByCodes implements IReturn<Eq5dCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5dGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Eq5dCollectionResponse(); }
}

// @Route("/opms/form/eq5d/save", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostSave implements IReturn<Eq5dSingleResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    public constructor(init?: Partial<Eq5dPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/save/ids", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostSaveWithIds implements IReturn<Eq5dSingleResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<Eq5dPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/save/codes", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dSingleResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostSaveWithCodes implements IReturn<Eq5dSingleResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<Eq5dPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dSingleResponse(); }
}

// @Route("/opms/form/eq5d/delete", "DELETE")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5dDelete implements IReturn<Eq5dDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    public constructor(init?: Partial<Eq5dDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5dDeleteResponse(); }
}

// @Route("/opms/form/eq5d/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5dDeleteWithId implements IReturn<Eq5dDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<Eq5dDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5dDeleteResponse(); }
}

// @Route("/opms/form/eq5d/delete/ids", "DELETE")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5dDeleteWithIds implements IReturn<Eq5dDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<Eq5dDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5dDeleteResponse(); }
}

// @Route("/opms/form/eq5d/delete/codes", "DELETE")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dDeleteResponse)", StatusCode=500)
// @DataContract
export class Eq5dDeleteByCodes implements IReturn<Eq5dDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<Eq5dDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new Eq5dDeleteResponse(); }
}

// @Route("/opms/form/eq5d/validate", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostValidate implements IReturn<Eq5dValidationResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    public constructor(init?: Partial<Eq5dPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dValidationResponse(); }
}

// @Route("/opms/form/eq5d/validate/ids", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostValidateWithIds implements IReturn<Eq5dValidationResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5dPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dValidationResponse(); }
}

// @Route("/opms/form/eq5d/validate/codes", "POST")
// @Api(Description="Form - Eq5d")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.Eq5dValidationResponse)", StatusCode=500)
// @DataContract
export class Eq5dPostValidateWithCodes implements IReturn<Eq5dValidationResponse>
{
    // @DataMember(Order=1)
    public form: Eq5d;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<Eq5dPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'Eq5dPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new Eq5dValidationResponse(); }
}

// @Route("/opms/form/fact-b/single/id/{Id}", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBGetSingleById implements IReturn<FactBSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FactBGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/single/ids", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBGetSingleByIds implements IReturn<FactBSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<FactBGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/single/codes", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBGetSingleByCodes implements IReturn<FactBSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<FactBGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/collection", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=500)
// @DataContract
export class FactBGetCollection implements IReturn<FactBCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<FactBGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBCollectionResponse(); }
}

// @Route("/opms/form/fact-b/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=500)
// @DataContract
export class FactBGetCollectionByIds implements IReturn<FactBCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<FactBGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBCollectionResponse(); }
}

// @Route("/opms/form/fact-b/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBCollectionResponse)", StatusCode=500)
// @DataContract
export class FactBGetCollectionByCodes implements IReturn<FactBCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<FactBGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FactBCollectionResponse(); }
}

// @Route("/opms/form/fact-b/save", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBPostSave implements IReturn<FactBSingleResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    public constructor(init?: Partial<FactBPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/save/ids", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBPostSaveWithIds implements IReturn<FactBSingleResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<FactBPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/save/codes", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBSingleResponse)", StatusCode=500)
// @DataContract
export class FactBPostSaveWithCodes implements IReturn<FactBSingleResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<FactBPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBSingleResponse(); }
}

// @Route("/opms/form/fact-b/delete", "DELETE")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=500)
// @DataContract
export class FactBDelete implements IReturn<FactBDeleteResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    public constructor(init?: Partial<FactBDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FactBDeleteResponse(); }
}

// @Route("/opms/form/fact-b/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=500)
// @DataContract
export class FactBDeleteWithId implements IReturn<FactBDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<FactBDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FactBDeleteResponse(); }
}

// @Route("/opms/form/fact-b/delete/ids", "DELETE")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=500)
// @DataContract
export class FactBDeleteWithIds implements IReturn<FactBDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<FactBDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FactBDeleteResponse(); }
}

// @Route("/opms/form/fact-b/delete/codes", "DELETE")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBDeleteResponse)", StatusCode=500)
// @DataContract
export class FactBDeleteByCodes implements IReturn<FactBDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<FactBDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new FactBDeleteResponse(); }
}

// @Route("/opms/form/fact-b/validate", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=500)
// @DataContract
export class FactBPostValidate implements IReturn<FactBValidationResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    public constructor(init?: Partial<FactBPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBValidationResponse(); }
}

// @Route("/opms/form/fact-b/validate/ids", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=500)
// @DataContract
export class FactBPostValidateWithIds implements IReturn<FactBValidationResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<FactBPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBValidationResponse(); }
}

// @Route("/opms/form/fact-b/validate/codes", "POST")
// @Api(Description="Form - Fact B")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.FactBValidationResponse)", StatusCode=500)
// @DataContract
export class FactBPostValidateWithCodes implements IReturn<FactBValidationResponse>
{
    // @DataMember(Order=1)
    public form: FactB;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<FactBPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FactBPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new FactBValidationResponse(); }
}

// @Route("/opms/form/hospital-service/single/id/{Id}", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetSingleById implements IReturn<HospitalServiceSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HospitalServiceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/single/ids", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetSingleByIds implements IReturn<HospitalServiceSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<HospitalServiceGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/single/codes", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetSingleByCodes implements IReturn<HospitalServiceSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<HospitalServiceGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/collection", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetCollection implements IReturn<HospitalServiceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<HospitalServiceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceCollectionResponse(); }
}

// @Route("/opms/form/hospital-service/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetCollectionByIds implements IReturn<HospitalServiceCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<HospitalServiceGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceCollectionResponse(); }
}

// @Route("/opms/form/hospital-service/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceGetCollectionByCodes implements IReturn<HospitalServiceCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<HospitalServiceGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new HospitalServiceCollectionResponse(); }
}

// @Route("/opms/form/hospital-service/save", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostSave implements IReturn<HospitalServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    public constructor(init?: Partial<HospitalServicePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/save/ids", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostSaveWithIds implements IReturn<HospitalServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<HospitalServicePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/save/codes", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceSingleResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostSaveWithCodes implements IReturn<HospitalServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<HospitalServicePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceSingleResponse(); }
}

// @Route("/opms/form/hospital-service/delete", "DELETE")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceDelete implements IReturn<HospitalServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    public constructor(init?: Partial<HospitalServiceDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new HospitalServiceDeleteResponse(); }
}

// @Route("/opms/form/hospital-service/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceDeleteWithId implements IReturn<HospitalServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<HospitalServiceDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new HospitalServiceDeleteResponse(); }
}

// @Route("/opms/form/hospital-service/delete/ids", "DELETE")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceDeleteWithIds implements IReturn<HospitalServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<HospitalServiceDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new HospitalServiceDeleteResponse(); }
}

// @Route("/opms/form/hospital-service/delete/codes", "DELETE")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class HospitalServiceDeleteByCodes implements IReturn<HospitalServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<HospitalServiceDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServiceDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new HospitalServiceDeleteResponse(); }
}

// @Route("/opms/form/hospital-service/validate", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostValidate implements IReturn<HospitalServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    public constructor(init?: Partial<HospitalServicePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceValidationResponse(); }
}

// @Route("/opms/form/hospital-service/validate/ids", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostValidateWithIds implements IReturn<HospitalServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<HospitalServicePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceValidationResponse(); }
}

// @Route("/opms/form/hospital-service/validate/codes", "POST")
// @Api(Description="Form - Hospital Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.HospitalServiceValidationResponse)", StatusCode=500)
// @DataContract
export class HospitalServicePostValidateWithCodes implements IReturn<HospitalServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: HospitalService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<HospitalServicePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'HospitalServicePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HospitalServiceValidationResponse(); }
}

// @Route("/opms/form/medication/single/id/{Id}", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationGetSingleById implements IReturn<MedicationSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicationGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/single/ids", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationGetSingleByIds implements IReturn<MedicationSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicationGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/single/codes", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationGetSingleByCodes implements IReturn<MedicationSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<MedicationGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/collection", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicationGetCollection implements IReturn<MedicationCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<MedicationGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationCollectionResponse(); }
}

// @Route("/opms/form/medication/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicationGetCollectionByIds implements IReturn<MedicationCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicationGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationCollectionResponse(); }
}

// @Route("/opms/form/medication/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationCollectionResponse)", StatusCode=500)
// @DataContract
export class MedicationGetCollectionByCodes implements IReturn<MedicationCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicationGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new MedicationCollectionResponse(); }
}

// @Route("/opms/form/medication/save", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationPostSave implements IReturn<MedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    public constructor(init?: Partial<MedicationPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/save/ids", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationPostSaveWithIds implements IReturn<MedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicationPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/save/codes", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationSingleResponse)", StatusCode=500)
// @DataContract
export class MedicationPostSaveWithCodes implements IReturn<MedicationSingleResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<MedicationPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationSingleResponse(); }
}

// @Route("/opms/form/medication/delete", "DELETE")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicationDelete implements IReturn<MedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    public constructor(init?: Partial<MedicationDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicationDeleteResponse(); }
}

// @Route("/opms/form/medication/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicationDeleteWithId implements IReturn<MedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<MedicationDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicationDeleteResponse(); }
}

// @Route("/opms/form/medication/delete/ids", "DELETE")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicationDeleteWithIds implements IReturn<MedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicationDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicationDeleteResponse(); }
}

// @Route("/opms/form/medication/delete/codes", "DELETE")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationDeleteResponse)", StatusCode=500)
// @DataContract
export class MedicationDeleteByCodes implements IReturn<MedicationDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<MedicationDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new MedicationDeleteResponse(); }
}

// @Route("/opms/form/medication/validate", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=500)
// @DataContract
export class MedicationPostValidate implements IReturn<MedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    public constructor(init?: Partial<MedicationPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationValidationResponse(); }
}

// @Route("/opms/form/medication/validate/ids", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=500)
// @DataContract
export class MedicationPostValidateWithIds implements IReturn<MedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicationPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationValidationResponse(); }
}

// @Route("/opms/form/medication/validate/codes", "POST")
// @Api(Description="Form - Medication")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.MedicationValidationResponse)", StatusCode=500)
// @DataContract
export class MedicationPostValidateWithCodes implements IReturn<MedicationValidationResponse>
{
    // @DataMember(Order=1)
    public form: Medication;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<MedicationPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'MedicationPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new MedicationValidationResponse(); }
}

// @Route("/opms/form/other-expense/single/id/{Id}", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetSingleById implements IReturn<OtherExpenseSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherExpenseGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/single/ids", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetSingleByIds implements IReturn<OtherExpenseSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherExpenseGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/single/codes", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetSingleByCodes implements IReturn<OtherExpenseSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OtherExpenseGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/collection", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetCollection implements IReturn<OtherExpenseCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<OtherExpenseGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseCollectionResponse(); }
}

// @Route("/opms/form/other-expense/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetCollectionByIds implements IReturn<OtherExpenseCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherExpenseGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseCollectionResponse(); }
}

// @Route("/opms/form/other-expense/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseCollectionResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseGetCollectionByCodes implements IReturn<OtherExpenseCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherExpenseGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OtherExpenseCollectionResponse(); }
}

// @Route("/opms/form/other-expense/save", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostSave implements IReturn<OtherExpenseSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    public constructor(init?: Partial<OtherExpensePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/save/ids", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostSaveWithIds implements IReturn<OtherExpenseSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherExpensePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/save/codes", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseSingleResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostSaveWithCodes implements IReturn<OtherExpenseSingleResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OtherExpensePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseSingleResponse(); }
}

// @Route("/opms/form/other-expense/delete", "DELETE")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseDelete implements IReturn<OtherExpenseDeleteResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    public constructor(init?: Partial<OtherExpenseDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherExpenseDeleteResponse(); }
}

// @Route("/opms/form/other-expense/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseDeleteWithId implements IReturn<OtherExpenseDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OtherExpenseDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherExpenseDeleteResponse(); }
}

// @Route("/opms/form/other-expense/delete/ids", "DELETE")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseDeleteWithIds implements IReturn<OtherExpenseDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherExpenseDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherExpenseDeleteResponse(); }
}

// @Route("/opms/form/other-expense/delete/codes", "DELETE")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseDeleteResponse)", StatusCode=500)
// @DataContract
export class OtherExpenseDeleteByCodes implements IReturn<OtherExpenseDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OtherExpenseDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpenseDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OtherExpenseDeleteResponse(); }
}

// @Route("/opms/form/other-expense/validate", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostValidate implements IReturn<OtherExpenseValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    public constructor(init?: Partial<OtherExpensePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseValidationResponse(); }
}

// @Route("/opms/form/other-expense/validate/ids", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostValidateWithIds implements IReturn<OtherExpenseValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherExpensePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseValidationResponse(); }
}

// @Route("/opms/form/other-expense/validate/codes", "POST")
// @Api(Description="Form - Other Expense")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OtherExpenseValidationResponse)", StatusCode=500)
// @DataContract
export class OtherExpensePostValidateWithCodes implements IReturn<OtherExpenseValidationResponse>
{
    // @DataMember(Order=1)
    public form: OtherExpense;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OtherExpensePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OtherExpensePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OtherExpenseValidationResponse(); }
}

// @Route("/opms/form/outpatient-service/single/id/{Id}", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetSingleById implements IReturn<OutpatientServiceSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OutpatientServiceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/single/ids", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetSingleByIds implements IReturn<OutpatientServiceSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OutpatientServiceGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/single/codes", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetSingleByCodes implements IReturn<OutpatientServiceSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<OutpatientServiceGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/collection", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetCollection implements IReturn<OutpatientServiceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<OutpatientServiceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceCollectionResponse(); }
}

// @Route("/opms/form/outpatient-service/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetCollectionByIds implements IReturn<OutpatientServiceCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OutpatientServiceGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceCollectionResponse(); }
}

// @Route("/opms/form/outpatient-service/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceCollectionResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceGetCollectionByCodes implements IReturn<OutpatientServiceCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<OutpatientServiceGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new OutpatientServiceCollectionResponse(); }
}

// @Route("/opms/form/outpatient-service/save", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostSave implements IReturn<OutpatientServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    public constructor(init?: Partial<OutpatientServicePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/save/ids", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostSaveWithIds implements IReturn<OutpatientServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OutpatientServicePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/save/codes", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceSingleResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostSaveWithCodes implements IReturn<OutpatientServiceSingleResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<OutpatientServicePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceSingleResponse(); }
}

// @Route("/opms/form/outpatient-service/delete", "DELETE")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceDelete implements IReturn<OutpatientServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    public constructor(init?: Partial<OutpatientServiceDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OutpatientServiceDeleteResponse(); }
}

// @Route("/opms/form/outpatient-service/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceDeleteWithId implements IReturn<OutpatientServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<OutpatientServiceDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OutpatientServiceDeleteResponse(); }
}

// @Route("/opms/form/outpatient-service/delete/ids", "DELETE")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceDeleteWithIds implements IReturn<OutpatientServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OutpatientServiceDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OutpatientServiceDeleteResponse(); }
}

// @Route("/opms/form/outpatient-service/delete/codes", "DELETE")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceDeleteResponse)", StatusCode=500)
// @DataContract
export class OutpatientServiceDeleteByCodes implements IReturn<OutpatientServiceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<OutpatientServiceDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServiceDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new OutpatientServiceDeleteResponse(); }
}

// @Route("/opms/form/outpatient-service/validate", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostValidate implements IReturn<OutpatientServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    public constructor(init?: Partial<OutpatientServicePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceValidationResponse(); }
}

// @Route("/opms/form/outpatient-service/validate/ids", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostValidateWithIds implements IReturn<OutpatientServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OutpatientServicePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceValidationResponse(); }
}

// @Route("/opms/form/outpatient-service/validate/codes", "POST")
// @Api(Description="Form - Outpatient Service")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.OutpatientServiceValidationResponse)", StatusCode=500)
// @DataContract
export class OutpatientServicePostValidateWithCodes implements IReturn<OutpatientServiceValidationResponse>
{
    // @DataMember(Order=1)
    public form: OutpatientService;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<OutpatientServicePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'OutpatientServicePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new OutpatientServiceValidationResponse(); }
}

// @Route("/opms/form/test/single/id/{Id}", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestGetSingleById implements IReturn<TestSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TestGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/single/ids", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestGetSingleByIds implements IReturn<TestSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TestGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/single/codes", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestGetSingleByCodes implements IReturn<TestSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TestGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/collection", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=500)
// @DataContract
export class TestGetCollection implements IReturn<TestCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TestGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestCollectionResponse(); }
}

// @Route("/opms/form/test/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=500)
// @DataContract
export class TestGetCollectionByIds implements IReturn<TestCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TestGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestCollectionResponse(); }
}

// @Route("/opms/form/test/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestCollectionResponse)", StatusCode=500)
// @DataContract
export class TestGetCollectionByCodes implements IReturn<TestCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TestGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TestCollectionResponse(); }
}

// @Route("/opms/form/test/save", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestPostSave implements IReturn<TestSingleResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    public constructor(init?: Partial<TestPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/save/ids", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestPostSaveWithIds implements IReturn<TestSingleResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TestPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/save/codes", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestSingleResponse)", StatusCode=500)
// @DataContract
export class TestPostSaveWithCodes implements IReturn<TestSingleResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TestPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestSingleResponse(); }
}

// @Route("/opms/form/test/delete", "DELETE")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=500)
// @DataContract
export class TestDelete implements IReturn<TestDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    public constructor(init?: Partial<TestDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TestDeleteResponse(); }
}

// @Route("/opms/form/test/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=500)
// @DataContract
export class TestDeleteWithId implements IReturn<TestDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TestDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TestDeleteResponse(); }
}

// @Route("/opms/form/test/delete/ids", "DELETE")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=500)
// @DataContract
export class TestDeleteWithIds implements IReturn<TestDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TestDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TestDeleteResponse(); }
}

// @Route("/opms/form/test/delete/codes", "DELETE")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestDeleteResponse)", StatusCode=500)
// @DataContract
export class TestDeleteByCodes implements IReturn<TestDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TestDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TestDeleteResponse(); }
}

// @Route("/opms/form/test/validate", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=500)
// @DataContract
export class TestPostValidate implements IReturn<TestValidationResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    public constructor(init?: Partial<TestPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestValidationResponse(); }
}

// @Route("/opms/form/test/validate/ids", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=500)
// @DataContract
export class TestPostValidateWithIds implements IReturn<TestValidationResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TestPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestValidationResponse(); }
}

// @Route("/opms/form/test/validate/codes", "POST")
// @Api(Description="Form - Test")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TestValidationResponse)", StatusCode=500)
// @DataContract
export class TestPostValidateWithCodes implements IReturn<TestValidationResponse>
{
    // @DataMember(Order=1)
    public form: Test;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TestPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TestPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TestValidationResponse(); }
}

// @Route("/opms/form/consent/single/id/{Id}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleById implements IReturn<ConsentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/single/ids", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleByIds implements IReturn<ConsentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/single/codes", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentGetSingleByCodes implements IReturn<ConsentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/collection", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollection implements IReturn<ConsentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConsentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollectionByIds implements IReturn<ConsentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentGetCollectionByCodes implements IReturn<ConsentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentCollectionResponse(); }
}

// @Route("/opms/form/consent/save", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSave implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/save/ids", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSaveWithIds implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/save/codes", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentSingleResponse)", StatusCode=500)
// @DataContract
export class ConsentPostSaveWithCodes implements IReturn<ConsentSingleResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentSingleResponse(); }
}

// @Route("/opms/form/consent/delete", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDelete implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteWithId implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/ids", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteWithIds implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/delete/codes", "DELETE")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentDeleteByCodes implements IReturn<ConsentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentDeleteResponse(); }
}

// @Route("/opms/form/consent/validate", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidate implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    public constructor(init?: Partial<ConsentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/consent/validate/ids", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidateWithIds implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/consent/validate/codes", "POST")
// @Api(Description="Form - Consent")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentPostValidateWithCodes implements IReturn<ConsentValidationResponse>
{
    // @DataMember(Order=1)
    public form: Consent;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentValidationResponse(); }
}

// @Route("/opms/form/consent-history/single/id/{Id}", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetSingleById implements IReturn<ConsentHistorySingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentHistoryGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/single/ids", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetSingleByIds implements IReturn<ConsentHistorySingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentHistoryGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/single/codes", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetSingleByCodes implements IReturn<ConsentHistorySingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ConsentHistoryGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/collection", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetCollection implements IReturn<ConsentHistoryCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ConsentHistoryGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistoryCollectionResponse(); }
}

// @Route("/opms/form/consent-history/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetCollectionByIds implements IReturn<ConsentHistoryCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistoryCollectionResponse(); }
}

// @Route("/opms/form/consent-history/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryCollectionResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryGetCollectionByCodes implements IReturn<ConsentHistoryCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ConsentHistoryCollectionResponse(); }
}

// @Route("/opms/form/consent-history/save", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostSave implements IReturn<ConsentHistorySingleResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    public constructor(init?: Partial<ConsentHistoryPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/save/ids", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostSaveWithIds implements IReturn<ConsentHistorySingleResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentHistoryPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/save/codes", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistorySingleResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostSaveWithCodes implements IReturn<ConsentHistorySingleResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ConsentHistoryPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistorySingleResponse(); }
}

// @Route("/opms/form/consent-history/delete", "DELETE")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryDelete implements IReturn<ConsentHistoryDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    public constructor(init?: Partial<ConsentHistoryDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentHistoryDeleteResponse(); }
}

// @Route("/opms/form/consent-history/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryDeleteWithId implements IReturn<ConsentHistoryDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ConsentHistoryDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentHistoryDeleteResponse(); }
}

// @Route("/opms/form/consent-history/delete/ids", "DELETE")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryDeleteWithIds implements IReturn<ConsentHistoryDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentHistoryDeleteResponse(); }
}

// @Route("/opms/form/consent-history/delete/codes", "DELETE")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryDeleteResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryDeleteByCodes implements IReturn<ConsentHistoryDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ConsentHistoryDeleteResponse(); }
}

// @Route("/opms/form/consent-history/validate", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostValidate implements IReturn<ConsentHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    public constructor(init?: Partial<ConsentHistoryPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistoryValidationResponse(); }
}

// @Route("/opms/form/consent-history/validate/ids", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostValidateWithIds implements IReturn<ConsentHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistoryValidationResponse(); }
}

// @Route("/opms/form/consent-history/validate/codes", "POST")
// @Api(Description="Form - Consent History")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ConsentHistoryValidationResponse)", StatusCode=500)
// @DataContract
export class ConsentHistoryPostValidateWithCodes implements IReturn<ConsentHistoryValidationResponse>
{
    // @DataMember(Order=1)
    public form: ConsentHistory;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ConsentHistoryPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ConsentHistoryPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ConsentHistoryValidationResponse(); }
}

// @Route("/opms/form/procomida/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleById implements IReturn<ProcomidaSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/single/ids", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleByIds implements IReturn<ProcomidaSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/single/codes", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetSingleByCodes implements IReturn<ProcomidaSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/collection", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollection implements IReturn<ProcomidaCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

// @Route("/opms/form/procomida/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollectionByIds implements IReturn<ProcomidaCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

// @Route("/opms/form/procomida/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaGetCollectionByCodes implements IReturn<ProcomidaCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaCollectionResponse(); }
}

// @Route("/opms/form/procomida/save", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSave implements IReturn<ProcomidaSingleResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/save/ids", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSaveWithIds implements IReturn<ProcomidaSingleResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/save/codes", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostSaveWithCodes implements IReturn<ProcomidaSingleResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaSingleResponse(); }
}

// @Route("/opms/form/procomida/delete", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDelete implements IReturn<ProcomidaDeleteResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

// @Route("/opms/form/procomida/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteWithId implements IReturn<ProcomidaDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

// @Route("/opms/form/procomida/delete/ids", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteWithIds implements IReturn<ProcomidaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

// @Route("/opms/form/procomida/delete/codes", "DELETE")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaDeleteByCodes implements IReturn<ProcomidaDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaDeleteResponse(); }
}

// @Route("/opms/form/procomida/validate", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidate implements IReturn<ProcomidaValidationResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    public constructor(init?: Partial<ProcomidaPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

// @Route("/opms/form/procomida/validate/ids", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidateWithIds implements IReturn<ProcomidaValidationResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

// @Route("/opms/form/procomida/validate/codes", "POST")
// @Api(Description="Form - Procomida")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaPostValidateWithCodes implements IReturn<ProcomidaValidationResponse>
{
    // @DataMember(Order=1)
    public form: Procomida;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaValidationResponse(); }
}

// @Route("/opms/form/procomida-assistance/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleById implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/single/ids", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleByIds implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/single/codes", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetSingleByCodes implements IReturn<ProcomidaAssistanceSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaAssistanceGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/collection", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollection implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaAssistanceGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

// @Route("/opms/form/procomida-assistance/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollectionByIds implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

// @Route("/opms/form/procomida-assistance/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceGetCollectionByCodes implements IReturn<ProcomidaAssistanceCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaAssistanceCollectionResponse(); }
}

// @Route("/opms/form/procomida-assistance/save", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSave implements IReturn<ProcomidaAssistanceSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistancePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/save/ids", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSaveWithIds implements IReturn<ProcomidaAssistanceSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaAssistancePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/save/codes", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostSaveWithCodes implements IReturn<ProcomidaAssistanceSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaAssistancePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceSingleResponse(); }
}

// @Route("/opms/form/procomida-assistance/delete", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDelete implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistanceDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

// @Route("/opms/form/procomida-assistance/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteWithId implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

// @Route("/opms/form/procomida-assistance/delete/ids", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteWithIds implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

// @Route("/opms/form/procomida-assistance/delete/codes", "DELETE")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistanceDeleteByCodes implements IReturn<ProcomidaAssistanceDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistanceDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistanceDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaAssistanceDeleteResponse(); }
}

// @Route("/opms/form/procomida-assistance/validate", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidate implements IReturn<ProcomidaAssistanceValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    public constructor(init?: Partial<ProcomidaAssistancePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

// @Route("/opms/form/procomida-assistance/validate/ids", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidateWithIds implements IReturn<ProcomidaAssistanceValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistancePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

// @Route("/opms/form/procomida-assistance/validate/codes", "POST")
// @Api(Description="Form - Procomida Assistance")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaAssistanceValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaAssistancePostValidateWithCodes implements IReturn<ProcomidaAssistanceValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaAssistance;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaAssistancePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaAssistancePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaAssistanceValidationResponse(); }
}

// @Route("/opms/form/procomida-incomplete/single/id/{Id}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleById implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/single/ids", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleByIds implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/single/codes", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetSingleByCodes implements IReturn<ProcomidaIncompleteSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProcomidaIncompleteGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/collection", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollection implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProcomidaIncompleteGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

// @Route("/opms/form/procomida-incomplete/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollectionByIds implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

// @Route("/opms/form/procomida-incomplete/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteCollectionResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteGetCollectionByCodes implements IReturn<ProcomidaIncompleteCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProcomidaIncompleteCollectionResponse(); }
}

// @Route("/opms/form/procomida-incomplete/save", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSave implements IReturn<ProcomidaIncompleteSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompletePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/save/ids", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSaveWithIds implements IReturn<ProcomidaIncompleteSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaIncompletePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/save/codes", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteSingleResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostSaveWithCodes implements IReturn<ProcomidaIncompleteSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProcomidaIncompletePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteSingleResponse(); }
}

// @Route("/opms/form/procomida-incomplete/delete", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDelete implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompleteDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

// @Route("/opms/form/procomida-incomplete/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteWithId implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

// @Route("/opms/form/procomida-incomplete/delete/ids", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteWithIds implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

// @Route("/opms/form/procomida-incomplete/delete/codes", "DELETE")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteDeleteResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompleteDeleteByCodes implements IReturn<ProcomidaIncompleteDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompleteDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompleteDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProcomidaIncompleteDeleteResponse(); }
}

// @Route("/opms/form/procomida-incomplete/validate", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidate implements IReturn<ProcomidaIncompleteValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    public constructor(init?: Partial<ProcomidaIncompletePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

// @Route("/opms/form/procomida-incomplete/validate/ids", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidateWithIds implements IReturn<ProcomidaIncompleteValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompletePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

// @Route("/opms/form/procomida-incomplete/validate/codes", "POST")
// @Api(Description="Form - Procomida Incomplete")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProcomidaIncompleteValidationResponse)", StatusCode=500)
// @DataContract
export class ProcomidaIncompletePostValidateWithCodes implements IReturn<ProcomidaIncompleteValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProcomidaIncomplete;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProcomidaIncompletePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProcomidaIncompletePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProcomidaIncompleteValidationResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/single/id/{Id}", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetSingleById implements IReturn<ProsignaAssayResultSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/single/ids", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetSingleByIds implements IReturn<ProsignaAssayResultSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProsignaAssayResultGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/single/codes", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetSingleByCodes implements IReturn<ProsignaAssayResultSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProsignaAssayResultGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/collection", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetCollection implements IReturn<ProsignaAssayResultCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProsignaAssayResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetCollectionByIds implements IReturn<ProsignaAssayResultCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultGetCollectionByCodes implements IReturn<ProsignaAssayResultCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaAssayResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/save", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostSave implements IReturn<ProsignaAssayResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/save/ids", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostSaveWithIds implements IReturn<ProsignaAssayResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProsignaAssayResultPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/save/codes", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostSaveWithCodes implements IReturn<ProsignaAssayResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProsignaAssayResultPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/delete", "DELETE")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultDelete implements IReturn<ProsignaAssayResultDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaAssayResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultDeleteWithId implements IReturn<ProsignaAssayResultDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaAssayResultDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaAssayResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/delete/ids", "DELETE")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultDeleteWithIds implements IReturn<ProsignaAssayResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaAssayResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/delete/codes", "DELETE")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultDeleteByCodes implements IReturn<ProsignaAssayResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaAssayResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/validate", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostValidate implements IReturn<ProsignaAssayResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    public constructor(init?: Partial<ProsignaAssayResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultValidationResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/validate/ids", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostValidateWithIds implements IReturn<ProsignaAssayResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultValidationResponse(); }
}

// @Route("/opms/form/prosigna-assay-result/validate/codes", "POST")
// @Api(Description="Form - Prosigna Assay Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaAssayResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaAssayResultPostValidateWithCodes implements IReturn<ProsignaAssayResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaAssayResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaAssayResultPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaAssayResultPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaAssayResultValidationResponse(); }
}

// @Route("/opms/form/prosigna-result/single/id/{Id}", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetSingleById implements IReturn<ProsignaResultSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaResultGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/single/ids", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetSingleByIds implements IReturn<ProsignaResultSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProsignaResultGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/single/codes", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetSingleByCodes implements IReturn<ProsignaResultSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ProsignaResultGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/collection", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetCollection implements IReturn<ProsignaResultCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ProsignaResultGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-result/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetCollectionByIds implements IReturn<ProsignaResultCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaResultGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-result/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultCollectionResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultGetCollectionByCodes implements IReturn<ProsignaResultCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaResultGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ProsignaResultCollectionResponse(); }
}

// @Route("/opms/form/prosigna-result/save", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostSave implements IReturn<ProsignaResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    public constructor(init?: Partial<ProsignaResultPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/save/ids", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostSaveWithIds implements IReturn<ProsignaResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProsignaResultPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/save/codes", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultSingleResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostSaveWithCodes implements IReturn<ProsignaResultSingleResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ProsignaResultPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultSingleResponse(); }
}

// @Route("/opms/form/prosigna-result/delete", "DELETE")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultDelete implements IReturn<ProsignaResultDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    public constructor(init?: Partial<ProsignaResultDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-result/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultDeleteWithId implements IReturn<ProsignaResultDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ProsignaResultDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-result/delete/ids", "DELETE")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultDeleteWithIds implements IReturn<ProsignaResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProsignaResultDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-result/delete/codes", "DELETE")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultDeleteResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultDeleteByCodes implements IReturn<ProsignaResultDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ProsignaResultDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ProsignaResultDeleteResponse(); }
}

// @Route("/opms/form/prosigna-result/validate", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostValidate implements IReturn<ProsignaResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    public constructor(init?: Partial<ProsignaResultPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultValidationResponse(); }
}

// @Route("/opms/form/prosigna-result/validate/ids", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostValidateWithIds implements IReturn<ProsignaResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaResultPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultValidationResponse(); }
}

// @Route("/opms/form/prosigna-result/validate/codes", "POST")
// @Api(Description="Form - Prosigna Result")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ProsignaResultValidationResponse)", StatusCode=500)
// @DataContract
export class ProsignaResultPostValidateWithCodes implements IReturn<ProsignaResultValidationResponse>
{
    // @DataMember(Order=1)
    public form: ProsignaResult;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ProsignaResultPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ProsignaResultPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ProsignaResultValidationResponse(); }
}

// @Route("/opms/form/shipment-sample/single/id/{Id}", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetSingleById implements IReturn<ShipmentSampleSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ShipmentSampleGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/single/ids", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetSingleByIds implements IReturn<ShipmentSampleSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ShipmentSampleGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/single/codes", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetSingleByCodes implements IReturn<ShipmentSampleSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<ShipmentSampleGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/collection", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetCollection implements IReturn<ShipmentSampleCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<ShipmentSampleGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleCollectionResponse(); }
}

// @Route("/opms/form/shipment-sample/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetCollectionByIds implements IReturn<ShipmentSampleCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ShipmentSampleGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleCollectionResponse(); }
}

// @Route("/opms/form/shipment-sample/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleGetCollectionByCodes implements IReturn<ShipmentSampleCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<ShipmentSampleGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new ShipmentSampleCollectionResponse(); }
}

// @Route("/opms/form/shipment-sample/save", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostSave implements IReturn<ShipmentSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    public constructor(init?: Partial<ShipmentSamplePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/save/ids", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostSaveWithIds implements IReturn<ShipmentSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ShipmentSamplePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/save/codes", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleSingleResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostSaveWithCodes implements IReturn<ShipmentSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<ShipmentSamplePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleSingleResponse(); }
}

// @Route("/opms/form/shipment-sample/delete", "DELETE")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleDelete implements IReturn<ShipmentSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    public constructor(init?: Partial<ShipmentSampleDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ShipmentSampleDeleteResponse(); }
}

// @Route("/opms/form/shipment-sample/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleDeleteWithId implements IReturn<ShipmentSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<ShipmentSampleDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ShipmentSampleDeleteResponse(); }
}

// @Route("/opms/form/shipment-sample/delete/ids", "DELETE")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleDeleteWithIds implements IReturn<ShipmentSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ShipmentSampleDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ShipmentSampleDeleteResponse(); }
}

// @Route("/opms/form/shipment-sample/delete/codes", "DELETE")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class ShipmentSampleDeleteByCodes implements IReturn<ShipmentSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<ShipmentSampleDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSampleDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new ShipmentSampleDeleteResponse(); }
}

// @Route("/opms/form/shipment-sample/validate", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostValidate implements IReturn<ShipmentSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    public constructor(init?: Partial<ShipmentSamplePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleValidationResponse(); }
}

// @Route("/opms/form/shipment-sample/validate/ids", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostValidateWithIds implements IReturn<ShipmentSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ShipmentSamplePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleValidationResponse(); }
}

// @Route("/opms/form/shipment-sample/validate/codes", "POST")
// @Api(Description="Form - Shipment Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.ShipmentSampleValidationResponse)", StatusCode=500)
// @DataContract
export class ShipmentSamplePostValidateWithCodes implements IReturn<ShipmentSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: ShipmentSample;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<ShipmentSamplePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'ShipmentSamplePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new ShipmentSampleValidationResponse(); }
}

// @Route("/opms/form/tissue-sample/single/id/{Id}", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetSingleById implements IReturn<TissueSampleSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/single/ids", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetSingleByIds implements IReturn<TissueSampleSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/single/codes", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetSingleByCodes implements IReturn<TissueSampleSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/collection", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetCollection implements IReturn<TissueSampleCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TissueSampleGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetCollectionByIds implements IReturn<TissueSampleCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleGetCollectionByCodes implements IReturn<TissueSampleCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample/save", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostSave implements IReturn<TissueSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    public constructor(init?: Partial<TissueSamplePostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/save/ids", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostSaveWithIds implements IReturn<TissueSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSamplePostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/save/codes", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostSaveWithCodes implements IReturn<TissueSampleSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSamplePostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSingleResponse(); }
}

// @Route("/opms/form/tissue-sample/delete", "DELETE")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleDelete implements IReturn<TissueSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    public constructor(init?: Partial<TissueSampleDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleDeleteWithId implements IReturn<TissueSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample/delete/ids", "DELETE")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleDeleteWithIds implements IReturn<TissueSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample/delete/codes", "DELETE")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleDeleteByCodes implements IReturn<TissueSampleDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample/validate", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostValidate implements IReturn<TissueSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    public constructor(init?: Partial<TissueSamplePostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleValidationResponse(); }
}

// @Route("/opms/form/tissue-sample/validate/ids", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostValidateWithIds implements IReturn<TissueSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSamplePostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleValidationResponse(); }
}

// @Route("/opms/form/tissue-sample/validate/codes", "POST")
// @Api(Description="Form - Tissue Sample")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSamplePostValidateWithCodes implements IReturn<TissueSampleValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSample;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSamplePostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSamplePostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/single/id/{Id}", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetSingleById implements IReturn<TissueSampleShipmentSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleShipmentGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/single/ids", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetSingleByIds implements IReturn<TissueSampleShipmentSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleShipmentGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/single/codes", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetSingleByCodes implements IReturn<TissueSampleShipmentSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleShipmentGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/collection", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetCollection implements IReturn<TissueSampleShipmentCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TissueSampleShipmentGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetCollectionByIds implements IReturn<TissueSampleShipmentCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentGetCollectionByCodes implements IReturn<TissueSampleShipmentCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleShipmentCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/save", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostSave implements IReturn<TissueSampleShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    public constructor(init?: Partial<TissueSampleShipmentPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/save/ids", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostSaveWithIds implements IReturn<TissueSampleShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSampleShipmentPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/save/codes", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostSaveWithCodes implements IReturn<TissueSampleShipmentSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSampleShipmentPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/delete", "DELETE")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentDelete implements IReturn<TissueSampleShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    public constructor(init?: Partial<TissueSampleShipmentDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleShipmentDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentDeleteWithId implements IReturn<TissueSampleShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleShipmentDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleShipmentDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/delete/ids", "DELETE")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentDeleteWithIds implements IReturn<TissueSampleShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleShipmentDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/delete/codes", "DELETE")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentDeleteByCodes implements IReturn<TissueSampleShipmentDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleShipmentDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/validate", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostValidate implements IReturn<TissueSampleShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    public constructor(init?: Partial<TissueSampleShipmentPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/validate/ids", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostValidateWithIds implements IReturn<TissueSampleShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-shipment/validate/codes", "POST")
// @Api(Description="Form - Tissue Sample Shipment")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleShipmentValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleShipmentPostValidateWithCodes implements IReturn<TissueSampleShipmentValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleShipment;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleShipmentPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleShipmentPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleShipmentValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/single/id/{Id}", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetSingleById implements IReturn<TissueSampleSubmissionSingleResponse>, IFormGetSingleById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleSubmissionGetSingleById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetSingleById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/single/ids", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetSingleByIds implements IReturn<TissueSampleSubmissionSingleResponse>, IFormGetSingleByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionId?: number;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleSubmissionGetSingleByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetSingleByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/single/codes", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetSingleByCodes implements IReturn<TissueSampleSubmissionSingleResponse>, IFormGetSingleByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    // @DataMember(Order=5)
    public institutionCode: string;

    // @DataMember(Order=6)
    public createPatient?: boolean;

    // @DataMember(Order=7)
    public createEvent?: boolean;

    // @DataMember(Order=8)
    public createForm?: boolean;

    public constructor(init?: Partial<TissueSampleSubmissionGetSingleByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetSingleByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/collection", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetCollection implements IReturn<TissueSampleSubmissionCollectionResponse>, IFormGetCollection
{

    public constructor(init?: Partial<TissueSampleSubmissionGetCollection>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetCollection'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/collection/ids/{PatientId}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetCollectionByIds implements IReturn<TissueSampleSubmissionCollectionResponse>, IFormGetCollectionByIds
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionGetCollectionByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetCollectionByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/collection/ids/{PatientStudyNumber}/{EventDefinitionCode}/{EventRepeat}", "GET")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=403)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionCollectionResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionGetCollectionByCodes implements IReturn<TissueSampleSubmissionCollectionResponse>, IFormGetCollectionByCodes
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionGetCollectionByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionGetCollectionByCodes'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TissueSampleSubmissionCollectionResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/save", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostSave implements IReturn<TissueSampleSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    public constructor(init?: Partial<TissueSampleSubmissionPostSave>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostSave'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/save/ids", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostSaveWithIds implements IReturn<TissueSampleSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSampleSubmissionPostSaveWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostSaveWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/save/codes", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionSingleResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostSaveWithCodes implements IReturn<TissueSampleSubmissionSingleResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    // @DataMember(Order=6)
    public formRepeat?: number;

    // @DataMember(Order=7)
    public createPatient?: boolean;

    // @DataMember(Order=8)
    public createEvent?: boolean;

    public constructor(init?: Partial<TissueSampleSubmissionPostSaveWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostSaveWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionSingleResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/delete", "DELETE")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionDelete implements IReturn<TissueSampleSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    public constructor(init?: Partial<TissueSampleSubmissionDelete>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionDelete'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/delete/id/{Id}", "DELETE")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionDeleteWithId implements IReturn<TissueSampleSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<TissueSampleSubmissionDeleteWithId>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionDeleteWithId'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/delete/ids", "DELETE")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionDeleteWithIds implements IReturn<TissueSampleSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientId?: number;

    // @DataMember(Order=2)
    public eventDefinitionId?: number;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionDeleteWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionDeleteWithIds'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/delete/codes", "DELETE")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionDeleteResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionDeleteByCodes implements IReturn<TissueSampleSubmissionDeleteResponse>
{
    // @DataMember(Order=1)
    public patientStudyNumber: string;

    // @DataMember(Order=2)
    public eventDefinitionCode: string;

    // @DataMember(Order=3)
    public eventRepeat?: number;

    // @DataMember(Order=4)
    public formRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionDeleteByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionDeleteByCodes'; }
    public getMethod() { return 'DELETE'; }
    public createResponse() { return new TissueSampleSubmissionDeleteResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/validate", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostValidate implements IReturn<TissueSampleSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    public constructor(init?: Partial<TissueSampleSubmissionPostValidate>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostValidate'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/validate/ids", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostValidateWithIds implements IReturn<TissueSampleSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    // @DataMember(Order=2)
    public institutionId: number;

    // @DataMember(Order=3)
    public patientId?: number;

    // @DataMember(Order=4)
    public eventDefinitionId: number;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionPostValidateWithIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostValidateWithIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionValidationResponse(); }
}

// @Route("/opms/form/tissue-sample-submission/validate/codes", "POST")
// @Api(Description="Form - Tissue Sample Submission")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.Form.TissueSampleSubmissionValidationResponse)", StatusCode=500)
// @DataContract
export class TissueSampleSubmissionPostValidateWithCodes implements IReturn<TissueSampleSubmissionValidationResponse>
{
    // @DataMember(Order=1)
    public form: TissueSampleSubmission;

    // @DataMember(Order=2)
    public institutionCode: string;

    // @DataMember(Order=3)
    public patientStudyNumber: string;

    // @DataMember(Order=4)
    public eventDefinitionCode: string;

    // @DataMember(Order=5)
    public eventRepeat?: number;

    public constructor(init?: Partial<TissueSampleSubmissionPostValidateWithCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'TissueSampleSubmissionPostValidateWithCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new TissueSampleSubmissionValidationResponse(); }
}

// @Route("/opms/file/view/{Id}", "GET")
export class GetFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<GetFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download/{Id}", "GET")
export class DownloadFile implements IReturn<Blob>, IGet
{
    public id?: number;

    public constructor(init?: Partial<DownloadFile>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadFile'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/file/download-all/{PatientId}/{FormDefinitionId}/{FormId}", "GET")
export class DownloadAllFiles implements IReturn<Blob>, IGet
{
    public patientId?: number;
    public formDefinitionId?: number;
    public formId?: number;

    public constructor(init?: Partial<DownloadAllFiles>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'DownloadAllFiles'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new Blob(); }
}

// @Route("/opms/event-state/id/{Id}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=500)
// @DataContract
export class EventStatesById implements IReturn<EventStateResponse>, IPatientGetEventStatesById
{
    // @DataMember(Order=1)
    public id?: number;

    public constructor(init?: Partial<EventStatesById>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventStatesById'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventStateResponse(); }
}

// @Route("/opms/event-state/study-number/{StudyNumber}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.EventStateResponse)", StatusCode=500)
// @DataContract
export class EventStatesByStudyNumber implements IReturn<EventStateResponse>, IPatientGetEventStatesByStudyNumber
{
    // @DataMember(Order=1)
    public studyNumber: string;

    public constructor(init?: Partial<EventStatesByStudyNumber>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'EventStatesByStudyNumber'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new EventStateResponse(); }
}

// @Route("/opms/form-state/ids/{Id}/{EventDefinitionId}/{EventRepeat}", "GET")
// @Api(Description="")
// @ApiResponse(Description="Unauthorized.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=401)
// @ApiResponse(Description="Forbidden.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=403)
// @ApiResponse(Description="Not Found.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=404)
// @ApiResponse(Description="Bad Request.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=400)
// @ApiResponse(Description="Internal Server Error.", ResponseType="typeof(__Opms.EventState.FormStateResponse)", StatusCode=500)
// @DataContract
export class FormStatesByIds implements IReturn<FormStateResponse>, IPatientGetFormStatesByIds
{
    // @DataMember(Order=1)
    public id?: number;

    // @DataMember(Order=1)
    public eventDefinitionId?: number;

    // @DataMember(Order=1)
    public eventRepeat?: number;

    public constructor(init?: Partial<FormStatesByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'FormStatesByIds'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new FormStateResponse(); }
}

export class PostHasOpmsPermissionsByIds implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupId?: number;
    public coordinatingGroupId?: number;
    public institutionId?: number;
    public patientId?: number;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByIds>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByIds'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class PostHasOpmsPermissionsByCodes implements IReturn<HasOpmsPermissionCollectionResponse>
{
    public masterGroupCode: string;
    public coordinatingGroupCode: string;
    public institutionCode: string;
    public patientStudyNumber: string;
    public permissions: Permission[];

    public constructor(init?: Partial<PostHasOpmsPermissionsByCodes>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'PostHasOpmsPermissionsByCodes'; }
    public getMethod() { return 'POST'; }
    public createResponse() { return new HasOpmsPermissionCollectionResponse(); }
}

export class GetInstitutionPersonnel implements IReturn<InstitutionPersonnelResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnel>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnel'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelResponse(); }
}

export class GetInstitutionPersonnelNames implements IReturn<InstitutionPersonnelNamesResponse>, IGet
{
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetInstitutionPersonnelNames>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetInstitutionPersonnelNames'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new InstitutionPersonnelNamesResponse(); }
}

export class GetTrialPersonnelHasActiveRole implements IReturn<TrialPersonnelActiveRoleResponse>, IGet
{
    public personId: number;
    public institutionCode: string;
    public roles: number[];

    public constructor(init?: Partial<GetTrialPersonnelHasActiveRole>) { (Object as any).assign(this, init); }
    public getTypeName() { return 'GetTrialPersonnelHasActiveRole'; }
    public getMethod() { return 'GET'; }
    public createResponse() { return new TrialPersonnelActiveRoleResponse(); }
}

